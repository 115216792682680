import { distinctUntilChanged, fromEvent, map, of, throttleTime } from 'rxjs';
import { throttle } from 'throttle-debounce';
import type { Observable } from 'rxjs';
import settings from 'configurations/application';
import element from 'utils/element';
import breakpoints from 'utils/breakpoints';
import appProd from 'utils/product';
import { Product } from 'contracts';

import ServiceBase from '../service-base';

import ApplicationHeader from './header';
import ApplicationContainer from './container';

interface ApplicationService {
  loading: boolean;
  viewportType: 'desktop' | 'mobile';
}

class Application extends ServiceBase<ApplicationService> {
  name = 'application';

  header = ApplicationHeader;

  container = ApplicationContainer;

  private loadingActorsRef = new Set();

  constructor() {
    super({ initialState: { loading: false, viewportType: breakpoints.max('md') ? 'mobile' : 'desktop' } });

    window.addEventListener(
      'resize',
      throttle(150, () => {
        this.set((data) => ({ ...data, viewportType: breakpoints.max('md') ? 'mobile' : 'desktop' }));
      })
    );
  }

  get onViewportTypeChange$(): Observable<ApplicationService['viewportType']> {
    return super.onChange$.pipe(
      map((data) => data.viewportType),
      distinctUntilChanged()
    );
  }

  get onLoad$(): Observable<boolean> {
    return super.onChange$.pipe(
      map((data) => data.loading),
      distinctUntilChanged()
    );
  }

  get onScroll$(): Observable<(Event & { target: HTMLDivElement }) | undefined> {
    const root = document.getElementById(settings.react.rootDivId);

    if (!root) return of(undefined);

    return fromEvent(root, 'scroll').pipe(
      throttleTime(150, undefined, { leading: false, trailing: true }),
      map((event) => event as Event & { target: HTMLDivElement })
    );
  }

  get loading(): boolean {
    return this.data.loading;
  }

  get current(): Product {
    return appProd.current;
  }

  get LiveJasmin(): boolean {
    return this.current === Product.LiveJasmin;
  }

  get Oranum(): boolean {
    return this.current === Product.Oranum;
  }

  startLoading<T extends object>(origin: T): void {
    this.loadingActorsRef.add(origin);
    this.set({
      ...this.data,
      loading: true,
    });
  }

  stopLoading<T extends object>(origin: T): void {
    this.loadingActorsRef.delete(origin);

    if (!this.loadingActorsRef.size) {
      this.set({
        ...this.data,
        loading: false,
      });
    }
  }

  scrollTop(top: number): void {
    element(window).scrollTop(top);
  }
}

export default new Application();
