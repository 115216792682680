import type { OAuthResponse, OAuthResponseError } from './contracts';

/* eslint-disable camelcase */
export const emptyOAuthResponse: OAuthResponse = {
  token_type: '',
  expires_in: 0,
  access_token: '',
  refresh_token: '',
  is_limited_access: true,
};

export const emptyOAuthResponseError: OAuthResponseError = {
  error: '',
  error_description: 0,
  hint: '',
  message: false,
};
/* eslint-enable camelcase */
