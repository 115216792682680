import { distinctUntilChanged, filter, firstValueFrom, map } from 'rxjs';
import type { PascalCase } from 'contracts';

import ServiceBase from '../service-base';

import isAuthenticated from './is-authenticated';
import isExpired from './is-expired';

type ActivityStatus = 'authenticating' | 'revoking' | 'refreshing' | 'idle';

type ActivityService = Record<`setAs${PascalCase<Exclude<ActivityStatus, 'idle'>>}`, <T>(owner: T) => () => void>;

class Activity extends ServiceBase<ActivityStatus> implements ActivityService {
  name = 'authentication-event';

  private readonly actorsRef = new Set();

  constructor() {
    super({ initialState: isAuthenticated() && isExpired() ? 'authenticating' : 'idle' });
  }

  private setAsIdle<T>(owner: T): void {
    this.actorsRef.delete(owner);

    if (!this.actorsRef.size) {
      this.set('idle');
    }
  }

  private setStatus<T>(status: Exclude<ActivityStatus, 'idle'>, owner: T): () => void {
    this.actorsRef.add(owner);

    this.set(status);

    return () => {
      this.setAsIdle(owner);
    };
  }

  public toBeIdle(): Promise<void> {
    return firstValueFrom(
      super.onChange$.pipe(
        distinctUntilChanged(),
        filter((status) => status === 'idle'),
        map(() => undefined)
      )
    );
  }

  public setAsAuthenticating<T>(owner: T): () => void {
    return this.setStatus<T>('authenticating', owner);
  }

  public setAsRevoking<T>(owner: T): () => void {
    return this.setStatus<T>('revoking', owner);
  }

  public setAsRefreshing<T>(owner: T): () => void {
    return this.setStatus<T>('refreshing', owner);
  }
}

export default new Activity();
