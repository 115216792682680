import { filter, map } from 'rxjs';
import type { Observable } from 'rxjs';

import ServiceBase from '../service-base';

import isAuthenticated from './is-authenticated';

class EventStream extends ServiceBase<boolean> {
  name = 'authentication-event';

  constructor() {
    super({ initialState: isAuthenticated() });
  }

  get onChange$(): Observable<boolean> {
    return super.onChange$;
  }

  get onLogin$(): Observable<void> {
    return super.onChange$.pipe(
      filter((status) => status),
      map(() => undefined)
    );
  }

  get onLogout$(): Observable<void> {
    return super.onChange$.pipe(
      filter((status) => !status),
      map(() => undefined)
    );
  }

  set(authenticated: boolean): void {
    super.set(authenticated);
  }
}

export default new EventStream();
