import scheduler from 'services/scheduler';

import isAuthenticated from './is-authenticated';
import refreshToken from './tasks/refresh-token';

const taskScheduler = {
  update() {
    if (isAuthenticated()) {
      return scheduler.register(refreshToken);
    }

    scheduler.unregister(refreshToken);
  },
};

// trigger initial run on the first application load
taskScheduler.update();

export default taskScheduler;
