import is from 'utils/is';

import getAuthData from './get-auth-data';

const isAuthenticated = (): boolean => {
  const { accessToken, refreshToken, expiresAt } = getAuthData() || {};

  return Boolean(accessToken) && Boolean(refreshToken) && is.number(expiresAt);
};

export default isAuthenticated;
