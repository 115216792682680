import type { Observable } from 'rxjs';
import type { ReactNode } from 'react';

import ServiceBase from '../service-base';

class ApplicationHeader extends ServiceBase<ReactNode> {
  name = 'application-header';

  constructor() {
    super({ initialState: undefined });
  }

  get onContentChange$(): Observable<ReactNode> {
    return super.onChange$;
  }

  setContent(content: ReactNode): void {
    super.set(content);
  }

  clearContent(): void {
    super.set(undefined);
  }
}

export default new ApplicationHeader();
