import type { Task } from 'contracts';

import navigation from '../../navigation';
import getAuthData from '../get-auth-data';
import refresh from '../refresh-token';
import activity from '../activity';
import { threshold } from '../is-expired';

const refreshToken: Task = {
  replay: true,
  type: 'asap',
  onBeforeStart() {
    this.activityDone = activity.setAsRefreshing(this);
  },
  onFinish() {
    this.activityDone();
  },
  get interval() {
    const { expiresAt } = getAuthData() || { expiresAt: 0 };

    return Math.max(0, expiresAt - new Date().getTime() - threshold);
  },
  async run() {
    /**
     * "getAuthData" methods reads auth cookie, which is set to expire within the same
     * lifetime as our refresh token. Therefore, we can assume that our refresh token
     * is still valid while auth data is retrieved from given cookie.
     */
    const hasAuthCookieExpired = !getAuthData();

    if (hasAuthCookieExpired) {
      navigation.logout();

      return;
    }

    await refresh().catch(() => {
      navigation.logout();
    });
  },
};

export default refreshToken;
