import type { FetchResponse, RequestParams } from 'services/fetch';
import fetch, { ContentType } from 'services/fetch';

import type {
  BFFConsentCreatedResponse,
  DirectPayoutPerformerResponse,
  DirectPayoutPerformersResponse,
} from './data-contracts';

/**
 * @description Create a consent for a performer
*
 * @tags BffWeb, DirectPayout, PrivateRoute, GatewayExposed
 * @name BffCreateConsent
 * @request POST:/gw/bff/web/v2/consent/policies/direct-payout/consents
 * @secure
 * @response `201` `BFFConsentCreatedResponse` OK
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `any`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`

*/
export const bffCreateConsent = (
  data: {
    /**
     * @format float
     * @example 0.14
     */
    share: number;
    /** @example 123456 */
    performerId: number;
  },
  params: RequestParams = {}
): FetchResponse<BFFConsentCreatedResponse> => {
  return fetch<BFFConsentCreatedResponse>(`/gw/bff/web/v2/consent/policies/direct-payout/consents`, {
    method: 'POST',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Get performers for Direct payouts
*
 * @tags DirectPayout, BffWeb, PrivateRoute, GatewayExposed
 * @name GetDirectPayoutPerformers
 * @request GET:/gw/bff/web/v2/direct-payout/performers
 * @secure
 * @response `200` `DirectPayoutPerformersResponse` OK
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `any`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`

*/
export const getDirectPayoutPerformers = (
  query?: {
    /** Parameter for partial search by performer name */
    performerName?: string;
    /** Direct payout status */
    directPayoutStatus?: 'active' | 'pending' | 'rejected' | 'missing';
    /**
     * Limit of results.
     * @default 10
     */
    limit?: number;
    /**
     * The offset of the results.
     * @default 0
     */
    offset?: number;
  },
  params: RequestParams = {}
): FetchResponse<DirectPayoutPerformersResponse> => {
  return fetch<DirectPayoutPerformersResponse>(`/gw/bff/web/v2/direct-payout/performers`, {
    method: 'GET',
    query: query,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Get performers for Direct payouts
*
 * @tags DirectPayout, BffWeb, PrivateRoute, GatewayExposed
 * @name GetDirectPayoutPerformer
 * @request GET:/gw/bff/web/v2/direct-payout/performers/{performerId}
 * @secure
 * @response `200` `DirectPayoutPerformerResponse` OK
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `any`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`

*/
export const getDirectPayoutPerformer = (
  performerId: number,
  params: RequestParams = {}
): FetchResponse<DirectPayoutPerformerResponse> => {
  return fetch<DirectPayoutPerformerResponse>(`/gw/bff/web/v2/direct-payout/performers/${performerId}`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};
