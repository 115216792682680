import type { FetchResponse, RequestParams } from 'services/fetch';
import fetch from 'services/fetch';

import type {
  BulkCreateConsentConfigurationCookieRequest,
  CookieConsentConfigurationsResponse,
  CookieConsentResponse,
  CreateCookieConsentRequest,
} from './data-contracts';

/**
 * @description Find cookie consent configurations
*
 * @tags CookieConsents
 * @name FindCookieConsentConfigurations
 * @request GET:/public-gw/consent/v1/cookie-consent/configurations
 * @response `200` `CookieConsentConfigurationsResponse`
 * @response `400` `void`
 * @response `403` `void`
 * @response `500` `void`

*/
export const findCookieConsentConfigurations = (
  params: RequestParams = {}
): FetchResponse<CookieConsentConfigurationsResponse> => {
  return fetch<CookieConsentConfigurationsResponse>(`/public-gw/consent/v1/cookie-consent/configurations`, {
    method: 'GET',
    ...params,
  });
};

/**
 * @description Add unknown cookies to the configuration.
*
 * @tags CookieConsents
 * @name CreateCookieConsentConfigurationCookies
 * @request POST:/public-gw/consent/v1/cookie-consent/configurations/{consentConfigurationId}/cookies
 * @response `204` `void` OK
 * @response `400` `void`
 * @response `403` `void`
 * @response `404` `void`
 * @response `500` `void`

*/
export const createCookieConsentConfigurationCookies = <T>(
  consentConfigurationId: string,
  data: BulkCreateConsentConfigurationCookieRequest,
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/public-gw/consent/v1/cookie-consent/configurations/${consentConfigurationId}/cookies`, {
    method: 'POST',
    body: data,
    ...params,
  });
};

/**
 * @description Create a new cookie consent
*
 * @tags CookieConsents
 * @name CreateCookieConsent
 * @request POST:/public-gw/consent/v1/cookie-consent/consents
 * @response `201` `CookieConsentResponse`
 * @response `400` `{
    errors?: ({
  \** @example "400" *\
    status?: string,
  \** @example "Invalid request" *\
    title?: string,
  \** @example 1000 *\
    code?: number,
    details?: string,

})[],

}` Bad request error message
 * @response `403` `{
    errors?: ({
  \** @example "403" *\
    status?: string,
  \** @example "Forbidden" *\
    title?: string,
  \** @example 1002 *\
    code?: number,
  \** @example "Insufficient permissions" *\
    details?: string,

})[],

}` Forbidden error message
 * @response `404` `{
    errors?: ({
  \** @example "404" *\
    status?: string,
  \** @example "Not found" *\
    title?: string,
  \** @example 1001 *\
    code?: number,
  \** @example "Resource not found" *\
    details?: string,

})[],

}` Not Found Error message
 * @response `500` `{
    errors?: ({
  \** @example "500" *\
    status?: string,
  \** @example "Unexpected error occurred" *\
    title?: string,
  \** @example 7 *\
    code?: number,
  \** @example "An unexpected error occurred in SomeFile.php" *\
    details?: string,

})[],

}` Internal server error message

*/
export const createCookieConsent = (
  data: CreateCookieConsentRequest,
  params: RequestParams = {}
): FetchResponse<CookieConsentResponse> => {
  return fetch<CookieConsentResponse>(`/public-gw/consent/v1/cookie-consent/consents`, {
    method: 'POST',
    body: data,
    ...params,
  });
};
