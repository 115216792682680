import Cookies from 'js-cookie';

import utils from './utils';
import type { Authentication } from './contracts';

const getAuthData = (): Authentication | undefined => {
  const data = Cookies.get(utils.cookies.CACHE_KEY);

  return data ? (JSON.parse(data) as Authentication) : undefined;
};

export default getAuthData;
