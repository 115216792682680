import type { FetchResponse, RequestParams } from 'services/fetch';
import fetch from 'services/fetch';

import type {
  CropName,
  CropSource,
  MongoId,
  ProfilePictureModel,
  ProfilePictureModelCropArea,
  ProfilePictureModelWithUploadUrl,
  Status,
  TagGroup,
  VersionMediaKey,
} from './data-contracts';

/**
 * No description
*
 * @tags ProfilePicture
 * @name DeleteProfilePicture
 * @summary Soft delete of profile picture (mark as deleted in DB + remove files from MWH)
 * @request DELETE:/gw/channel/v1/performer/profile-pictures/{id}
 * @secure
 * @response `204` `void` Profile picture was deleted successfully.
 * @response `400` `void`
 * @response `404` `void`

*/
export const deleteProfilePicture = <T>(id: MongoId, params: RequestParams = {}): FetchResponse<T> => {
  return fetch<T>(`/gw/channel/v1/performer/profile-pictures/${id}`, {
    method: 'DELETE',
    ...params,
  });
};

/**
 * No description
*
 * @tags ProfilePicture
 * @name UpdateProfilePicture
 * @summary Applies changes to profile picture (marks as selected, updates tags and crops)
 * @request PATCH:/gw/channel/v1/performer/profile-pictures/{id}
 * @secure
 * @response `204` `void` Profile picture was updated successfully.
 * @response `400` `void`
 * @response `404` `{
    errors?: ({
  \** @example "404" *\
    status?: string,
  \** @example "Not found" *\
    title?: string,
  \** @example 1001 *\
    code?: number,
  \** @example "Resource not found" *\
    details?: string,

})[],

}` Not Found Error message

*/
export const updateProfilePicture = <T>(
  id: MongoId,
  data: {
    /** @example false */
    isSelected?: boolean;
    /**
     * @maxItems 1
     * @minItems 0
     */
    tags?: {
      /** @example "fetish" */
      name: string;
      /** Profile Picture Tag Group */
      group: TagGroup;
    }[];
  },
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/gw/channel/v1/performer/profile-pictures/${id}`, {
    method: 'PATCH',
    body: data,
    ...params,
  });
};

/**
 * No description
*
 * @tags ProfilePicture
 * @name FindProfilePictures
 * @summary Find profile pictures
 * @request GET:/gw/channel/v1/performer/profile-pictures
 * @secure
 * @response `200` `{
    data: (ProfilePictureModel)[],

}`
 * @response `400` `{
    errors?: ({
  \** @example "400" *\
    status?: string,
  \** @example "Invalid request" *\
    title?: string,
  \** @example 1000 *\
    code?: number,
    details?: string,

})[],

}` Bad request error message

*/
export const findProfilePictures = (
  query?: {
    /** status criteria */
    'criteria[statuses]'?: Status[];
    /** media key criteria */
    'criteria[mediaKeys]'?: VersionMediaKey[];
  },
  params: RequestParams = {}
): FetchResponse<{
  data: ProfilePictureModel[];
}> => {
  return fetch<{
    data: ProfilePictureModel[];
  }>(`/gw/channel/v1/performer/profile-pictures`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags ProfilePicture
 * @name CreateProfilePicture
 * @summary Create new profile picture
 * @request POST:/gw/channel/v1/performer/profile-pictures
 * @secure
 * @response `201` `{
    data: ProfilePictureModelWithUploadUrl,

}`
 * @response `400` `void`

*/
export const createProfilePicture = (
  data: {
    /** @example false */
    isSelected: boolean;
    /**
     * @maxItems 3
     * @minItems 2
     */
    crops: {
      /** Profile Picture Crop Name */
      name: CropName;
      /** Profile Picture Crop Source */
      source: CropSource;
      area: ProfilePictureModelCropArea;
    }[];
    /**
     * @maxItems 1
     * @minItems 1
     */
    tags?: {
      /** @example "fetish" */
      name: string;
      /** Profile Picture Tag Group */
      group: TagGroup;
    }[];
  },
  query?: {
    /** status criteria */
    'criteria[statuses]'?: Status[];
    /** media key criteria */
    'criteria[mediaKeys]'?: VersionMediaKey[];
  },
  params: RequestParams = {}
): FetchResponse<{
  data: ProfilePictureModelWithUploadUrl;
}> => {
  return fetch<{
    data: ProfilePictureModelWithUploadUrl;
  }>(`/gw/channel/v1/performer/profile-pictures`, {
    method: 'POST',
    query: query,
    body: data,
    ...params,
  });
};
