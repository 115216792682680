import type { FetchResponse, RequestParams } from 'services/fetch';
import fetch from 'services/fetch';

import type { AvailabilityResponseSchema, TokenResponseSchema } from './data-contracts';

/**
 * @description Jaws
*
 * @tags Jaws, PrivateRoute
 * @name GetJawsAvailability
 * @request GET:/gw/inner/v1/jaws/availability
 * @secure
 * @response `200` `AvailabilityResponseSchema`
 * @response `401` `void`
 * @response `403` `void`
 * @response `500` `void`

*/
export const getJawsAvailability = (params: RequestParams = {}): FetchResponse<AvailabilityResponseSchema> => {
  return fetch<AvailabilityResponseSchema>(`/gw/inner/v1/jaws/availability`, {
    method: 'GET',
    ...params,
  });
};

/**
 * @description Jaws
*
 * @tags Jaws, PrivateRoute
 * @name CreateJawsToken
 * @request POST:/gw/inner/v1/jaws/token
 * @secure
 * @response `201` `TokenResponseSchema`
 * @response `400` `({
    requestParams: object,
  \** @default "ERROR" *\
    status: "OK" | "ERROR",
      \**
          * @format int32
          * @example 0
          *\
    errorCode: number | null,
  \** @example "Error message" *\
    errorMessage: string | null,
    data: object,

} & {
  \** @example 400 *\
    errorCode?: any,

})` Bad request response
 * @response `401` `{
  \** @example "access_denied" *\
    error?: string,
      \**
          * copy of "message"
          * @example "The resource owner or authorization server denied the request."
          *\
    error_description?: string,
  \** @example "Missing "Authorization" header" *\
    hint?: string | null,
      \**
          * Deprecated, use error_description instead.
          * @deprecated
          * @example "The resource owner or authorization server denied the request."
          *\
    message?: string,

}` Unauthorized error message
 * @response `403` `({
  \** @example 403 *\
    errorCode?: any,

})` Forbidden request response
 * @response `404` `({
  \** @example 404 *\
    errorCode?: any,

})` Not found response
 * @response `500` `({
  \** @example 500 *\
    errorCode?: any,

})` Internal server error response

*/
export const createJawsToken = (params: RequestParams = {}): FetchResponse<TokenResponseSchema> => {
  return fetch<TokenResponseSchema>(`/gw/inner/v1/jaws/token`, {
    method: 'POST',
    ...params,
  });
};
