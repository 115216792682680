import Cookies from 'js-cookie';

import httpRequest from './http-request';
import eventStream from './event-stream';
import activity from './activity';
import taskScheduler from './task-scheduler';
import { OAuthAction } from './contracts';
import utils from './utils';

const http = httpRequest(OAuthAction.GET_TOKEN);

const signIn = (username: string, password: string): Promise<void> => {
  return new Promise(function Executor(resolve, reject) {
    const activityDone = activity.setAsAuthenticating(this);

    http.abort().send({ username, password }, (error, response) => {
      if (error) {
        activityDone();

        return reject(error);
      }

      const auth = utils.response.map(response);

      Cookies.set(utils.cookies.CACHE_KEY, JSON.stringify(auth), utils.cookies.attributes());

      activityDone();

      eventStream.set(true);

      taskScheduler.update();

      resolve();
    });
  });
};

export default signIn;
