import type { FetchResponse, RequestParams } from 'services/fetch';
import fetch, { ContentType } from 'services/fetch';

import type {
  AchievementContestLeadersResponse,
  AchievementContestResultResponse,
  AchievementContestWinnersResponse,
  AchievementWinnersResponse,
  BFFConsentCreatedResponse,
  ContestMainProgressResponse,
  DirectPayoutPerformerResponse,
  DirectPayoutPerformersResponse,
  InnerApiSuccess,
  ModelAccountListResponse,
  PayingMembersListResponse,
  PerformerData,
  PermissionsResponse,
  PersonalData,
  ReferralSharesResponse,
  SummerModelContestStatsResponse,
} from './data-contracts';

/**
 * @description Create a consent for a performer
*
 * @tags BffWeb, DirectPayout
 * @name BffCreateConsent
 * @request POST:/gw/bff/web/v2/consent/policies/direct-payout/consents
 * @secure
 * @response `201` `BFFConsentCreatedResponse` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const bffCreateConsent = (
  data: {
    /**
     * @format float
     * @example 0.14
     */
    share: number;
    /** @example 123456 */
    performerId: number;
  },
  params: RequestParams = {}
): FetchResponse<BFFConsentCreatedResponse> => {
  return fetch<BFFConsentCreatedResponse>(`/gw/bff/web/v2/consent/policies/direct-payout/consents`, {
    method: 'POST',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Get performers for Direct payouts
*
 * @tags DirectPayout, BffWeb
 * @name GetDirectPayoutPerformers
 * @request GET:/gw/bff/web/v2/direct-payout/performers
 * @secure
 * @response `200` `DirectPayoutPerformersResponse` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getDirectPayoutPerformers = (
  query?: {
    /** Parameter for partial search by performer name */
    performerName?: string;
    /** Direct payout status */
    directPayoutStatus?: 'active' | 'pending' | 'rejected' | 'missing';
    /**
     * Limit of results.
     * @default 10
     */
    limit?: number;
    /**
     * The offset of the results.
     * @default 0
     */
    offset?: number;
  },
  params: RequestParams = {}
): FetchResponse<DirectPayoutPerformersResponse> => {
  return fetch<DirectPayoutPerformersResponse>(`/gw/bff/web/v2/direct-payout/performers`, {
    method: 'GET',
    query: query,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Get performers for Direct payouts
*
 * @tags DirectPayout, BffWeb
 * @name GetDirectPayoutPerformer
 * @request GET:/gw/bff/web/v2/direct-payout/performers/{performerId}
 * @secure
 * @response `200` `DirectPayoutPerformerResponse` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getDirectPayoutPerformer = (
  performerId: number,
  params: RequestParams = {}
): FetchResponse<DirectPayoutPerformerResponse> => {
  return fetch<DirectPayoutPerformerResponse>(`/gw/bff/web/v2/direct-payout/performers/${performerId}`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Get "accounts" (performers) available to the user
*
 * @tags BffWeb
 * @name FindMyAccounts
 * @request GET:/gw/bff/web/v2/me/accounts
 * @secure
 * @response `200` `(InnerApiSuccess & ModelAccountListResponse)` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const findMyAccounts = (
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & ModelAccountListResponse> => {
  return fetch<InnerApiSuccess & ModelAccountListResponse>(`/gw/bff/web/v2/me/accounts`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Get list of paying members
*
 * @tags BffWeb
 * @name FindAllPayingMembers
 * @request GET:/gw/bff/web/v2/paying-members
 * @secure
 * @response `200` `(InnerApiSuccess & PayingMembersListResponse)` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const findAllPayingMembers = (
  query?: {
    /** Filters. */
    criteria?: (
      | {
          /** @example "tagId" */
          field: 'tagId';
          /** @example "IN" */
          operator: string;
          value: number[];
        }
      | {
          /** @example "q" */
          field: 'q';
          /** @example "=" */
          operator: string;
          /** @example "NickName" */
          value: string;
        }
    )[];
    /** Paying members order by paramters */
    orderBy?: {
      /** @example "member_name" */
      field: 'member_name' | 'total_spent_amount' | 'last_spent_at' | 'favourite';
      /** @example "asc" */
      direction?: 'asc' | 'desc';
    }[];
    /**
     * Limit of results.
     * @default 10
     */
    limit?: number;
    /**
     * The offset of the results.
     * @default 0
     */
    offset?: number;
  },
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & PayingMembersListResponse> => {
  return fetch<InnerApiSuccess & PayingMembersListResponse>(`/gw/bff/web/v2/paying-members`, {
    method: 'GET',
    query: query,
    responseType: 'json',
    ...params,
  });
};

/**
 * No description
*
 * @tags BffWeb
 * @name GetAllIndividualContestProgress
 * @request GET:/gw/bff/web/v2/performers/achievement/individual-contests/progress/{performerId}
 * @secure
 * @response `200` `(InnerApiSuccess & ContestMainProgressResponse)` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getAllIndividualContestProgress = (
  performerId: number,
  query?: {
    /**
     * An array of contest ids to get progresses for. If no value provided, then defaults will be returned
     * @default ["traffic-boost","conversion-score","engagement-score"]
     */
    'contestIds[]'?: (
      | 'traffic-boost'
      | 'conversion-score'
      | 'engagement-score'
      | 'december-contest'
      | 'international-cam-model-day'
      | 'welcome-bonus'
    )[];
  },
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & ContestMainProgressResponse> => {
  return fetch<InnerApiSuccess & ContestMainProgressResponse>(
    `/gw/bff/web/v2/performers/achievement/individual-contests/progress/${performerId}`,
    {
      method: 'GET',
      query: query,
      responseType: 'json',
      ...params,
    }
  );
};

/**
 * No description
*
 * @tags BffWeb
 * @name GetGroupContestLeaders
 * @request GET:/gw/bff/web/v2/performers/achievement/contest/{periodType}/leaders
 * @secure
 * @response `200` `(InnerApiSuccess & AchievementContestLeadersResponse)` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getGroupContestLeaders = (
  periodType: 'season' | 'month',
  query?: {
    /**
     * @min 2
     * @max 300
     * @default 100
     */
    limit?: number;
    /** @default false */
    includeTestAccounts?: boolean;
  },
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & AchievementContestLeadersResponse> => {
  return fetch<InnerApiSuccess & AchievementContestLeadersResponse>(
    `/gw/bff/web/v2/performers/achievement/contest/${periodType}/leaders`,
    {
      method: 'GET',
      query: query,
      responseType: 'json',
      ...params,
    }
  );
};

/**
 * No description
*
 * @tags BffWeb
 * @name GetAchievementWinners
 * @request GET:/gw/bff/web/v2/performers/achievements/winners
 * @secure
 * @response `200` `(InnerApiSuccess & AchievementWinnersResponse)` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getAchievementWinners = (
  query?: {
    /** @default false */
    withTestData?: boolean;
  },
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & AchievementWinnersResponse> => {
  return fetch<InnerApiSuccess & AchievementWinnersResponse>(`/gw/bff/web/v2/performers/achievements/winners`, {
    method: 'GET',
    query: query,
    responseType: 'json',
    ...params,
  });
};

/**
 * No description
*
 * @tags BffWeb
 * @name GetAchievementContestWinners
 * @request GET:/gw/bff/web/v2/performers/achievement/contest/{contestName}/winners
 * @deprecated
 * @secure
 * @response `200` `(InnerApiSuccess & AchievementContestWinnersResponse)` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getAchievementContestWinners = (
  contestName: 'oranum-monthly' | 'spring' | 'summer' | 'autumn' | 'finals',
  query?: {
    /**
     * @min 2
     * @max 300
     * @default 100
     */
    limit?: number;
  },
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & AchievementContestWinnersResponse> => {
  return fetch<InnerApiSuccess & AchievementContestWinnersResponse>(
    `/gw/bff/web/v2/performers/achievement/contest/${contestName}/winners`,
    {
      method: 'GET',
      query: query,
      responseType: 'json',
      ...params,
    }
  );
};

/**
 * @description Returns performer data by display name.
*
 * @tags Performer, BffWeb
 * @name GetPerformerDataByDisplayName
 * @request GET:/gw/bff/web/v2/performers/{displayName}
 * @secure
 * @response `200` `(InnerApiSuccess & PerformerData)`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getPerformerDataByDisplayName = (
  displayName: string,
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & PerformerData> => {
  return fetch<InnerApiSuccess & PerformerData>(`/gw/bff/web/v2/performers/${displayName}`, {
    method: 'GET',
    ...params,
  });
};

/**
 * No description
*
 * @tags BffWeb
 * @name GetSummerModelContestStats
 * @request GET:/gw/bff/web/v2/performers/{performerId}/summer-model-contest-stats
 * @secure
 * @response `200` `(InnerApiSuccess & SummerModelContestStatsResponse)` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getSummerModelContestStats = (
  performerId: number,
  query: {
    /**
     * DateTime from where the stats calculation should start
     * @format date
     * @example "2023-08-15"
     */
    from: string;
    /**
     * DateTime until the stats calculation should end
     * @format date
     * @example "2023-08-15"
     */
    to: string;
  },
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & SummerModelContestStatsResponse> => {
  return fetch<InnerApiSuccess & SummerModelContestStatsResponse>(
    `/gw/bff/web/v2/performers/${performerId}/summer-model-contest-stats`,
    {
      method: 'GET',
      query: query,
      responseType: 'json',
      ...params,
    }
  );
};

/**
 * @description Provides a set of permissions on which frontend is going to rely on in building a menu items tree
*
 * @tags BffWeb
 * @name GetPermissions
 * @request GET:/gw/bff/web/v2/permissions
 * @secure
 * @response `200` `(InnerApiSuccess & PermissionsResponse)` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `{
  \** @example "access_denied" *\
    error?: string,
      \**
          * copy of "message"
          * @example "The resource owner or authorization server denied the request."
          *\
    error_description?: string,
  \** @example "Missing "Authorization" header" *\
    hint?: string | null,
      \**
          * Deprecated, use error_description instead.
          * @deprecated
          * @example "The resource owner or authorization server denied the request."
          *\
    message?: string,

}` Unauthorized error message
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `429` `{
  \** @example "Request limit reached." *\
    error?: string,

}` Too many requests error message.

*/
export const getPermissions = (params: RequestParams = {}): FetchResponse<InnerApiSuccess & PermissionsResponse> => {
  return fetch<InnerApiSuccess & PermissionsResponse>(`/gw/bff/web/v2/permissions`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Returns the personal data properties for an authenticated user.
*
 * @tags Profile, BffWeb
 * @name GetProfilePersonalData
 * @request GET:/gw/bff/web/v2/profiles/persons/{personId}/personal-data
 * @secure
 * @response `200` `(InnerApiSuccess & PersonalData)`
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getProfilePersonalData = (
  personId: number,
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & PersonalData> => {
  return fetch<InnerApiSuccess & PersonalData>(`/gw/bff/web/v2/profiles/persons/${personId}/personal-data`, {
    method: 'GET',
    ...params,
  });
};

/**
 * @description Get referrals shares for specific referrer
*
 * @tags BffWeb
 * @name GetReferralsShares
 * @request GET:/gw/bff/web/v2/referrals/shares
 * @secure
 * @response `200` `(InnerApiSuccess & ReferralSharesResponse)` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getReferralsShares = (
  query?: {
    /** Numerical ID of Referral from Performercenter */
    externalEntityId?: number;
    /** Performercenter type */
    externalEntityType?: 'studio' | 'performer';
    /** @example "2020-12-01 12:00:00" */
    periodFrom?: string;
    /** @example "2020-12-01 12:00:00" */
    periodTo?: string;
    /** Referral status */
    referralStatus?: 'active' | 'calculation_disabled' | 'payment_disabled' | 'expired';
    /** Parameter for partial search by referral name */
    referralNameLike?: string;
    /**
     * Limit of results.
     * @default 10
     */
    limit?: number;
    /**
     * The offset of the results.
     * @default 0
     */
    offset?: number;
  },
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & ReferralSharesResponse> => {
  return fetch<InnerApiSuccess & ReferralSharesResponse>(`/gw/bff/web/v2/referrals/shares`, {
    method: 'GET',
    query: query,
    responseType: 'json',
    ...params,
  });
};

/**
 * No description
*
 * @tags BffWeb
 * @name GetGroupContestResult
 * @request GET:/gw/bff/web/v3/performers/achievement/contest/{contestName}/winners
 * @secure
 * @response `200` `(InnerApiSuccess & AchievementContestResultResponse)` OK
 * @response `400` `(InnerApiError & {
  \** @example 400 *\
    errorCode?: any,

})`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `(InnerApiError & {
  \** @example 404 *\
    errorCode?: any,

})`
 * @response `429` `void`

*/
export const getGroupContestResult = (
  contestName: 'oranum-monthly' | 'spring' | 'summer' | 'autumn' | 'finals',
  query?: {
    /**
     * @min 2
     * @max 300
     * @default 100
     */
    limit?: number;
  },
  params: RequestParams = {}
): FetchResponse<InnerApiSuccess & AchievementContestResultResponse> => {
  return fetch<InnerApiSuccess & AchievementContestResultResponse>(
    `/gw/bff/web/v3/performers/achievement/contest/${contestName}/winners`,
    {
      method: 'GET',
      query: query,
      responseType: 'json',
      ...params,
    }
  );
};
