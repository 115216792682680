import is from 'utils/is';
import happen from 'utils/happen';

import getAuthData from './get-auth-data';
import { secondsToMilliseconds } from './utils';

/*
 *  How much time we should anticipate refresh token call
 */
const threshold = secondsToMilliseconds(10);

const isExpired = (): boolean => {
  const { expiresAt } = getAuthData() || {};

  if (!is.number(expiresAt)) {
    return true;
  }

  return happen(new Date(expiresAt - threshold)).lessThan(new Date());
};

export { threshold };
export default isExpired;
