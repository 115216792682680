import { distinctUntilChanged, map } from 'rxjs';
import type { Observable } from 'rxjs';

import ServiceBase from '../service-base';

interface Container {
  showNavigationMenu: boolean;
}

class ApplicationContainer extends ServiceBase<Container> {
  name = 'application-container';

  constructor() {
    super({
      initialState: {
        showNavigationMenu: false,
      },
    });
  }

  get onNavigationMenuChange$(): Observable<boolean> {
    return super.onChange$.pipe(
      map((data) => data.showNavigationMenu),
      distinctUntilChanged()
    );
  }

  toggleNavigationMenu(open: Container['showNavigationMenu']): void {
    super.set({ ...this.data, showNavigationMenu: open });
  }

  get data(): Container {
    return super.data;
  }
}

export default new ApplicationContainer();
