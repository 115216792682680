import is from 'utils/is';

import getAuthData from './get-auth-data';

const getAccessToken = async (): Promise<string | undefined> => {
  const { expiresAt, accessToken } = getAuthData() || {};

  if (is.nullish(expiresAt) || is.nullish(accessToken)) {
    return undefined;
  }

  return accessToken;
};

export default getAccessToken;
