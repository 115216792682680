import Cookies from 'js-cookie';

import httpRequest from './http-request';
import getAuthData from './get-auth-data';
import eventStream from './event-stream';
import utils from './utils';
import { OAuthAction } from './contracts';
// eslint-disable-next-line no-duplicate-imports
import type { Authentication } from './contracts';

const http = httpRequest(OAuthAction.REFRESH_TOKEN);

const refreshToken = (): Promise<Authentication> => {
  const { refreshToken: token } = getAuthData() || {};

  return new Promise(function Executor(resolve, reject) {
    http
      .abort()
      // eslint-disable-next-line camelcase
      .send({ refresh_token: token }, (error, response) => {
        if (error) {
          return reject(error);
        }

        const auth = utils.response.map(response);

        Cookies.set(utils.cookies.CACHE_KEY, JSON.stringify(auth), utils.cookies.attributes());

        eventStream.set(true);

        resolve(auth);
      });
  });
};

export default refreshToken;
