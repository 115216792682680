import Cookies from 'js-cookie';

import httpRequest from './http-request';
import { OAuthAction } from './contracts';
import getAuthData from './get-auth-data';
import eventStream from './event-stream';
import activity from './activity';
import taskScheduler from './task-scheduler';
import utils from './utils';

const http = httpRequest(OAuthAction.REVOKE_TOKEN);

const signOut = async (): Promise<void> => {
  return new Promise<void>(function Executor(resolve) {
    const { accessToken } = getAuthData() || {};

    const activityDone = activity.setAsRevoking(this);

    // eslint-disable-next-line camelcase
    http.abort().send({ token: accessToken as string, token_type_hint: 'access_token' }, () => {
      Cookies.remove(utils.cookies.CACHE_KEY, utils.cookies.attributes());

      activityDone();

      eventStream.set(false);

      taskScheduler.update();

      resolve();
    });
  });
};

export default signOut;
