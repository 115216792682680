import type { FetchResponse, RequestParams } from 'services/fetch';
import fetch, { ContentType } from 'services/fetch';

import type { DirectPayoutAvailabilityResponse, DirectPayoutSummaryResponse } from './data-contracts';

/**
 * @description Get Direct payout Availability for studio
*
 * @tags DirectPayout, PrivateRoute, GatewayExposed
 * @name GetDirectPayoutAvailability
 * @request GET:/gw/inner/v2/direct-payout-availability
 * @secure
 * @response `200` `DirectPayoutAvailabilityResponse` OK
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `any`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`

*/
export const getDirectPayoutAvailability = (
  params: RequestParams = {}
): FetchResponse<DirectPayoutAvailabilityResponse> => {
  return fetch<DirectPayoutAvailabilityResponse>(`/gw/inner/v2/direct-payout-availability`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Get summary for Direct payouts
*
 * @tags DirectPayout, PrivateRoute, GatewayExposed
 * @name GetDirectPayoutSummary
 * @request GET:/gw/inner/v2/direct-payout/summary
 * @secure
 * @response `200` `DirectPayoutSummaryResponse` OK
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `any`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`

*/
export const getDirectPayoutSummary = (params: RequestParams = {}): FetchResponse<DirectPayoutSummaryResponse> => {
  return fetch<DirectPayoutSummaryResponse>(`/gw/inner/v2/direct-payout/summary`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Delete Direct Payout of performer
*
 * @tags DirectPayout, PrivateRoute, GatewayExposed
 * @name DeleteDirectPayout
 * @request DELETE:/gw/inner/v2/direct-payout/{performerId}
 * @secure
 * @response `204` `void` No Content
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `any`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`

*/
export const deleteDirectPayout = <T>(performerId: string, params: RequestParams = {}): FetchResponse<T> => {
  return fetch<T>(`/gw/inner/v2/direct-payout/${performerId}`, {
    method: 'DELETE',
    ...params,
  });
};

/**
 * @description Reject performer Direct payouts
*
 * @tags DirectPayout, BffWeb, PrivateRoute, GatewayExposed
 * @name RejectPerformerDirectPayoutApplication
 * @request POST:/gw/inner/v2/direct-payout/performers/{performerId}/reject
 * @secure
 * @response `204` `void` OK
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `any`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`

*/
export const rejectPerformerDirectPayoutApplication = <T>(
  performerId: string,
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/gw/inner/v2/direct-payout/performers/${performerId}/reject`, {
    method: 'POST',
    ...params,
  });
};

/**
 * @description Update performer technical studio for Direct payouts
*
 * @tags DirectPayout, BffWeb, PrivateRoute, GatewayExposed
 * @name UpdateDirectPayoutPerformerTechnicalStudio
 * @request PUT:/gw/inner/v2/direct-payout/performers/{performerId}/technical-studio
 * @secure
 * @response `204` `void` OK
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `any`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`

*/
export const updateDirectPayoutPerformerTechnicalStudio = <T>(
  performerId: string,
  data: {
    /** @format email */
    email?: string;
    /** @format password */
    password?: string;
  },
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/gw/inner/v2/direct-payout/performers/${performerId}/technical-studio`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    ...params,
  });
};
