import type { FetchResponse, RequestParams } from 'services/fetch';
import fetch, { ContentType } from 'services/fetch';

import type {
  AddModelFlow,
  Category,
  Country,
  Ethnicity,
  MigrationStatus,
  Nationality,
  Performer,
  PropertyCollection,
  RegistrationFlow,
  State,
  UserDetailsPerson,
  UserDetailsStudio,
} from './data-contracts';

/**
 * @description Check if a email is available
*
 * @tags Registration
 * @name InnerV2IdentitiesEmailAvailabilityCreate
 * @request POST:/public-gw/inner/v2/identities/email-availability
 * @secure
 * @response `200` `{
    data?: {
  \** @example true *\
    isValid: boolean,
  \** @example true *\
    isAvailable: boolean,

},

}` Email availability
 * @response `401` `void`
 * @response `429` `void`

*/
export const innerV2IdentitiesEmailAvailabilityCreate = (
  data: {
    /** @example "example@example.com" */
    email: string;
  },
  params: RequestParams = {}
): FetchResponse<{
  data?: {
    /** @example true */
    isValid: boolean;
    /** @example true */
    isAvailable: boolean;
  };
}> => {
  return fetch<{
    data?: {
      /** @example true */
      isValid: boolean;
      /** @example true */
      isAvailable: boolean;
    };
  }>(`/public-gw/inner/v2/identities/email-availability`, {
    method: 'POST',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Check if a display name is available
*
 * @tags Registration
 * @name InnerV2IdentitiesDisplayNameAvailabilityCreate
 * @request POST:/public-gw/inner/v2/identities/display-name-availability
 * @secure
 * @response `200` `{
    data?: {
  \** @example true *\
    isAvailable: boolean,
    bannedWord?: string | null,

},

}` Display name availability
 * @response `401` `void`
 * @response `429` `void`

*/
export const innerV2IdentitiesDisplayNameAvailabilityCreate = (
  data: {
    /** @example "DisplayName" */
    displayName: string;
    /** @format int32 */
    categoryId?: number | null;
  },
  params: RequestParams = {}
): FetchResponse<{
  data?: {
    /** @example true */
    isAvailable: boolean;
    bannedWord?: string | null;
  };
}> => {
  return fetch<{
    data?: {
      /** @example true */
      isAvailable: boolean;
      bannedWord?: string | null;
    };
  }>(`/public-gw/inner/v2/identities/display-name-availability`, {
    method: 'POST',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Check if a refferal is available
*
 * @tags Registration
 * @name InnerV2IdentitiesReferredByAvailabilityCreate
 * @request POST:/public-gw/inner/v2/identities/referred-by-availability
 * @secure
 * @response `200` `{
    data?: {
  \** @example true *\
    isAvailable: boolean,

},

}` Referrer name availability
 * @response `401` `void`
 * @response `429` `void`

*/
export const innerV2IdentitiesReferredByAvailabilityCreate = (
  data: {
    /** @example "DisplayName" */
    referredBy: string;
  },
  params: RequestParams = {}
): FetchResponse<{
  data?: {
    /** @example true */
    isAvailable: boolean;
  };
}> => {
  return fetch<{
    data?: {
      /** @example true */
      isAvailable: boolean;
    };
  }>(`/public-gw/inner/v2/identities/referred-by-availability`, {
    method: 'POST',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow
*
 * @tags Registration
 * @name InnerV2RegistrationCreate
 * @request POST:/public-gw/inner/v2/registration
 * @secure
 * @response `200` `RegistrationFlow` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationCreate = (
  data: {
    /** @example "livejasmin" */
    product: 'livejasmin' | 'jasmin' | 'docler' | 'oranum' | 'bimbim';
    /** @example 1 */
    studioId?: number | null;
  },
  params: RequestParams = {}
): FetchResponse<RegistrationFlow> => {
  return fetch<RegistrationFlow>(`/public-gw/inner/v2/registration`, {
    method: 'POST',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow
*
 * @tags Registration
 * @name InnerV2RegistrationCreateAccountUpdate
 * @request PUT:/public-gw/inner/v2/registration/create-account/{flowId}
 * @secure
 * @response `200` `RegistrationFlow` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationCreateAccountUpdate = (
  flowId: string,
  data: {
    /**
     * @format email
     * @example "example@example.com"
     */
    email: string;
    /** @example 12345 */
    password: string;
    /** @example true */
    isPolicyAccepted: boolean;
    psid?: string | null;
  },
  params: RequestParams = {}
): FetchResponse<RegistrationFlow> => {
  return fetch<RegistrationFlow>(`/public-gw/inner/v2/registration/create-account/${flowId}`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Request an email confirmation
*
 * @tags Registration, Users, AccountSettings
 * @name RequestEmailConfirmation
 * @request PUT:/public-gw/inner/v2/users/{userId}/email-confirmation-request
 * @secure
 * @response `204` `void` Email was resent successfully
 * @response `400` `{
    requestParams: object,
  \** @default "ERROR" *\
    status: "OK" | "ERROR",
      \**
          * @format int32
          * @example 0
          *\
    errorCode: number | null,
  \** @example "Error message" *\
    errorMessage: string | null,
    data: object,

}`
 * @response `401` `void`
 * @response `404` `{
    requestParams: object,
  \** @default "ERROR" *\
    status: "OK" | "ERROR",
      \**
          * @format int32
          * @example 0
          *\
    errorCode: number | null,
  \** @example "Error message" *\
    errorMessage: string | null,
    data: object,

}`
 * @response `429` `void`

*/
export const requestEmailConfirmation = <T>(
  userId: number,
  data: {
    /** @example "emailconfirmationcode123" */
    expiredCode?: string | null;
  },
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/public-gw/inner/v2/users/${userId}/email-confirmation-request`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    ...params,
  });
};

/**
 * @description Confirm an email of a user
*
 * @tags Registration, Users, AccountSettings
 * @name ConfirmEmail
 * @request PATCH:/public-gw/inner/v2/users/{userId}/email-confirmation-request
 * @secure
 * @response `204` `{
    requestParams: object,
  \** @default "OK" *\
    status: "OK" | "ERROR",
      \**
          * @format int32
          * @example 404
          *\
    errorCode: number | null,
  \** @example "Operation failed due to unknown error." *\
    errorMessage: string | null,
    data: object,

}`
 * @response `400` `{
    requestParams: object,
  \** @default "ERROR" *\
    status: "OK" | "ERROR",
      \**
          * @format int32
          * @example 0
          *\
    errorCode: number | null,
  \** @example "Error message" *\
    errorMessage: string | null,
    data: object,

}`
 * @response `401` `void`
 * @response `404` `{
    requestParams: object,
  \** @default "ERROR" *\
    status: "OK" | "ERROR",
      \**
          * @format int32
          * @example 0
          *\
    errorCode: number | null,
  \** @example "Error message" *\
    errorMessage: string | null,
    data: object,

}`
 * @response `410` `{
    requestParams: object,
  \** @default "ERROR" *\
    status: "OK" | "ERROR",
      \**
          * @format int32
          * @example 0
          *\
    errorCode: number | null,
  \** @example "Error message" *\
    errorMessage: string | null,
    data: object,

}`
 * @response `429` `void`

*/
export const confirmEmail = (
  userId: number,
  data: {
    /** @example "emailconfirmationcode123" */
    code: string;
  },
  params: RequestParams = {}
): FetchResponse<{
  requestParams: object;
  /** @default "OK" */
  status: 'OK' | 'ERROR';
  /**
   * @format int32
   * @example 404
   */
  errorCode: number | null;
  /** @example "Operation failed due to unknown error." */
  errorMessage: string | null;
  data: object;
}> => {
  return fetch<{
    requestParams: object;
    /** @default "OK" */
    status: 'OK' | 'ERROR';
    /**
     * @format int32
     * @example 404
     */
    errorCode: number | null;
    /** @example "Operation failed due to unknown error." */
    errorMessage: string | null;
    data: object;
  }>(`/public-gw/inner/v2/users/${userId}/email-confirmation-request`, {
    method: 'PATCH',
    body: data,
    contentType: ContentType.Json,
    ...params,
  });
};

/**
 * @description Get performer
*
 * @tags AccountSettings, Registration
 * @name InnerV2PerformersDetail
 * @request GET:/gw/inner/v2/performers/{performerId}
 * @secure
 * @response `200` `{
    data?: Performer,

}` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2PerformersDetail = (
  performerId: number,
  params: RequestParams = {}
): FetchResponse<{
  data?: Performer;
}> => {
  return fetch<{
    data?: Performer;
  }>(`/gw/inner/v2/performers/${performerId}`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Get performer persons
*
 * @tags AccountSettings, Registration
 * @name GetPerformerPersons
 * @request GET:/gw/inner/v2/performers/{performerId}/persons
 * @secure
 * @response `200` `{
    data: {
    items: ({
  \** @example 1 *\
    id: number,
  \** @example "John Doe" *\
    fullName: string,
  \** @example "123455GAS" *\
    idNumber: string,
  \** @example 1 *\
    idTypeId: number,
  \** @example 1 *\
    nationalityId: number,
  \** @example "LU" *\
    countryCode: string,
  \** @example 923097600 *\
    birthDate: string,
  \** @example 1 *\
    genderId: number,

})[],

},

}` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const getPerformerPersons = (
  performerId: number,
  params: RequestParams = {}
): FetchResponse<{
  data: {
    items: {
      /** @example 1 */
      id: number;
      /** @example "John Doe" */
      fullName: string;
      /** @example "123455GAS" */
      idNumber: string;
      /** @example 1 */
      idTypeId: number;
      /** @example 1 */
      nationalityId: number;
      /** @example "LU" */
      countryCode: string;
      /** @example 923097600 */
      birthDate: string;
      /** @example 1 */
      genderId: number;
    }[];
  };
}> => {
  return fetch<{
    data: {
      items: {
        /** @example 1 */
        id: number;
        /** @example "John Doe" */
        fullName: string;
        /** @example "123455GAS" */
        idNumber: string;
        /** @example 1 */
        idTypeId: number;
        /** @example 1 */
        nationalityId: number;
        /** @example "LU" */
        countryCode: string;
        /** @example 923097600 */
        birthDate: string;
        /** @example 1 */
        genderId: number;
      }[];
    };
  }>(`/gw/inner/v2/performers/${performerId}/persons`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Get performer properties
*
 * @tags AccountSettings, Registration
 * @name InnerV2PerformersPropertiesDetail
 * @request GET:/gw/inner/v2/performers/{performerId}/properties
 * @secure
 * @response `200` `PropertyCollection` Returned in case request was successful
 * @response `401` `void`
 * @response `403` `any`
 * @response `429` `void`

*/
export const innerV2PerformersPropertiesDetail = (
  performerId: number,
  params: RequestParams = {}
): FetchResponse<PropertyCollection> => {
  return fetch<PropertyCollection>(`/gw/inner/v2/performers/${performerId}/properties`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * No description
*
 * @tags Registration
 * @name InnerV2EthnicitiesList
 * @summary Get list of ethnicities
 * @request GET:/gw/inner/v2/ethnicities
 * @secure
 * @response `200` `{
    data?: {
    items: (Ethnicity)[],

},

}` Get list of ethnicities
 * @response `401` `void`
 * @response `429` `void`

*/
export const innerV2EthnicitiesList = (
  params: RequestParams = {}
): FetchResponse<{
  data?: {
    items: Ethnicity[];
  };
}> => {
  return fetch<{
    data?: {
      items: Ethnicity[];
    };
  }>(`/gw/inner/v2/ethnicities`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * No description
*
 * @tags Registration
 * @name InnerV2NationalitiesList
 * @summary Get list of nationalities
 * @request GET:/gw/inner/v2/nationalities
 * @secure
 * @response `200` `{
    data?: (Nationality)[],

}` Get list of nationalities
 * @response `401` `void`
 * @response `429` `void`

*/
export const innerV2NationalitiesList = (
  params: RequestParams = {}
): FetchResponse<{
  data?: Nationality[];
}> => {
  return fetch<{
    data?: Nationality[];
  }>(`/gw/inner/v2/nationalities`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * No description
*
 * @tags Registration
 * @name InnerV2CountriesList
 * @summary Get list of countries
 * @request GET:/gw/inner/v2/countries
 * @secure
 * @response `200` `{
    data?: (Country)[],

}` Get list of countries
 * @response `401` `void`
 * @response `429` `void`

*/
export const innerV2CountriesList = (
  params: RequestParams = {}
): FetchResponse<{
  data?: Country[];
}> => {
  return fetch<{
    data?: Country[];
  }>(`/gw/inner/v2/countries`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * No description
*
 * @tags Registration
 * @name InnerV2CountriesStatesDetail
 * @summary Get list of states
 * @request GET:/gw/inner/v2/countries/{countryCode}/states
 * @secure
 * @response `200` `{
    data?: (State)[],

}` Get list of contry states
 * @response `401` `void`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2CountriesStatesDetail = (
  countryCode: string,
  params: RequestParams = {}
): FetchResponse<{
  data?: State[];
}> => {
  return fetch<{
    data?: State[];
  }>(`/gw/inner/v2/countries/${countryCode}/states`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * @description User details
*
 * @tags Registration
 * @name InnerV2MeList
 * @request GET:/gw/inner/v2/me
 * @secure
 * @response `200` `{
    data?: {
      \**
          * @format int32
          * @example 1
          *\
    id?: number,
      \**
          * Atm only 'limited-studio' is recommended to use.
          * @example ["limited-studio","user","performer","studio"]
          *\
    roles?: (string)[],
  \** @example "jane.doe@foo.bar" *\
    email?: string,
      \**
          * @format date-time
          * @example "2017-07-21T17:32:28Z"
          *\
    emailConfirmedAt?: string | null,
  \** @example true *\
    isLimitedAccessEnabled?: boolean,
  \** @example "US" *\
    registrationCountryCode?: string | null,
  \** @example true *\
    isForgetDataRequested?: boolean | null,
      \**
          * @format date-time
          * @example "2017-07-21T17:32:28Z"
          *\
    forgetDataRequestedAt?: string | null,
  \** @example "waiting_for_data_collection" *\
    personalDataDownloadRequestStatus?: string | null,
    forgetDataRequest?: {
  \** @example true *\
    isForgetDataRequested?: boolean | null,
      \**
          * @format date-time
          * @example "2017-07-21T17:32:28Z"
          *\
    requestedAt?: string | null,

},
    marketingCommunicationConsent?: {
  \** @example true *\
    status?: boolean | null,
      \**
          * @format date-time
          * @example "2017-07-21T17:32:28Z"
          *\
    requestedAt?: string | null,

},
    sensitiveDataCollectionConsent?: {
  \** @example true *\
    status?: boolean | null,
      \**
          * @format date-time
          * @example "2017-07-21T17:32:28Z"
          *\
    requestedAt?: string | null,

},
  \** @example "email@foo.bar" *\
    pendingEmail?: string | null,
    status?: "enabled" | "disabled" | "deleted" | "terminated" | null,
  \** @example "livejasmin" *\
    registrationSite?: "livejasmin" | "jasmin" | "lj" | "mcmessenger" | "bimbim" | "docler",
  \** @format uuid *\
    registrationFlow?: string | null,
    registrationFinished?: boolean,
  \** @format int32 *\
    defaultPerformerId?: number | null,
    person?: UserDetailsPerson,
    studio?: UserDetailsStudio,

},

}` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `{
  \** @example "access_denied" *\
    error?: string,
      \**
          * copy of "message"
          * @example "The resource owner or authorization server denied the request."
          *\
    error_description?: string,
  \** @example "Missing "Authorization" header" *\
    hint?: string | null,
      \**
          * Deprecated, use error_description instead.
          * @deprecated
          * @example "The resource owner or authorization server denied the request."
          *\
    message?: string,

}` Unauthorized error message
 * @response `404` `any`
 * @response `429` `{
  \** @example "Request limit reached." *\
    error?: string,

}` Too many requests error message.

*/
export const innerV2MeList = (
  params: RequestParams = {}
): FetchResponse<{
  data?: {
    /**
     * @format int32
     * @example 1
     */
    id?: number;
    /**
     * Atm only 'limited-studio' is recommended to use.
     * @example ["limited-studio","user","performer","studio"]
     */
    roles?: string[];
    /** @example "jane.doe@foo.bar" */
    email?: string;
    /**
     * @format date-time
     * @example "2017-07-21T17:32:28Z"
     */
    emailConfirmedAt?: string | null;
    /** @example true */
    isLimitedAccessEnabled?: boolean;
    /** @example "US" */
    registrationCountryCode?: string | null;
    /** @example true */
    isForgetDataRequested?: boolean | null;
    /**
     * @format date-time
     * @example "2017-07-21T17:32:28Z"
     */
    forgetDataRequestedAt?: string | null;
    /** @example "waiting_for_data_collection" */
    personalDataDownloadRequestStatus?: string | null;
    forgetDataRequest?: {
      /** @example true */
      isForgetDataRequested?: boolean | null;
      /**
       * @format date-time
       * @example "2017-07-21T17:32:28Z"
       */
      requestedAt?: string | null;
    };
    marketingCommunicationConsent?: {
      /** @example true */
      status?: boolean | null;
      /**
       * @format date-time
       * @example "2017-07-21T17:32:28Z"
       */
      requestedAt?: string | null;
    };
    sensitiveDataCollectionConsent?: {
      /** @example true */
      status?: boolean | null;
      /**
       * @format date-time
       * @example "2017-07-21T17:32:28Z"
       */
      requestedAt?: string | null;
    };
    /** @example "email@foo.bar" */
    pendingEmail?: string | null;
    status?: 'enabled' | 'disabled' | 'deleted' | 'terminated' | null;
    /** @example "livejasmin" */
    registrationSite?: 'livejasmin' | 'jasmin' | 'lj' | 'mcmessenger' | 'bimbim' | 'docler';
    /** @format uuid */
    registrationFlow?: string | null;
    registrationFinished?: boolean;
    /** @format int32 */
    defaultPerformerId?: number | null;
    person?: UserDetailsPerson;
    studio?: UserDetailsStudio;
  };
}> => {
  return fetch<{
    data?: {
      /**
       * @format int32
       * @example 1
       */
      id?: number;
      /**
       * Atm only 'limited-studio' is recommended to use.
       * @example ["limited-studio","user","performer","studio"]
       */
      roles?: string[];
      /** @example "jane.doe@foo.bar" */
      email?: string;
      /**
       * @format date-time
       * @example "2017-07-21T17:32:28Z"
       */
      emailConfirmedAt?: string | null;
      /** @example true */
      isLimitedAccessEnabled?: boolean;
      /** @example "US" */
      registrationCountryCode?: string | null;
      /** @example true */
      isForgetDataRequested?: boolean | null;
      /**
       * @format date-time
       * @example "2017-07-21T17:32:28Z"
       */
      forgetDataRequestedAt?: string | null;
      /** @example "waiting_for_data_collection" */
      personalDataDownloadRequestStatus?: string | null;
      forgetDataRequest?: {
        /** @example true */
        isForgetDataRequested?: boolean | null;
        /**
         * @format date-time
         * @example "2017-07-21T17:32:28Z"
         */
        requestedAt?: string | null;
      };
      marketingCommunicationConsent?: {
        /** @example true */
        status?: boolean | null;
        /**
         * @format date-time
         * @example "2017-07-21T17:32:28Z"
         */
        requestedAt?: string | null;
      };
      sensitiveDataCollectionConsent?: {
        /** @example true */
        status?: boolean | null;
        /**
         * @format date-time
         * @example "2017-07-21T17:32:28Z"
         */
        requestedAt?: string | null;
      };
      /** @example "email@foo.bar" */
      pendingEmail?: string | null;
      status?: 'enabled' | 'disabled' | 'deleted' | 'terminated' | null;
      /** @example "livejasmin" */
      registrationSite?: 'livejasmin' | 'jasmin' | 'lj' | 'mcmessenger' | 'bimbim' | 'docler';
      /** @format uuid */
      registrationFlow?: string | null;
      registrationFinished?: boolean;
      /** @format int32 */
      defaultPerformerId?: number | null;
      person?: UserDetailsPerson;
      studio?: UserDetailsStudio;
    };
  }>(`/gw/inner/v2/me`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow details
*
 * @tags Registration
 * @name InnerV2RegistrationDetail
 * @request GET:/gw/inner/v2/registration/{flowId}
 * @secure
 * @response `200` `RegistrationFlow` Returned in case request was successful
 * @response `401` `void`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationDetail = (
  flowId: string,
  params: RequestParams = {}
): FetchResponse<RegistrationFlow> => {
  return fetch<RegistrationFlow>(`/gw/inner/v2/registration/${flowId}`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow
*
 * @tags Registration
 * @name InnerV2RegistrationCreateAccountUpdate2
 * @request PUT:/gw/inner/v2/registration/create-account/{flowId}
 * @originalName innerV2RegistrationCreateAccountUpdate
 * @duplicate
 * @secure
 * @response `200` `RegistrationFlow` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationCreateAccountUpdate2 = (
  flowId: string,
  data: {
    /**
     * @format email
     * @example "example@example.com"
     */
    email: string;
    /** @example 12345 */
    password: string;
    /** @example true */
    isPolicyAccepted: boolean;
    psid?: string | null;
  },
  params: RequestParams = {}
): FetchResponse<RegistrationFlow> => {
  return fetch<RegistrationFlow>(`/gw/inner/v2/registration/create-account/${flowId}`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow
*
 * @tags Registration
 * @name InnerV2RegistrationAccountTypeUpdate
 * @request PUT:/gw/inner/v2/registration/account-type/{flowId}
 * @secure
 * @response `200` `RegistrationFlow` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationAccountTypeUpdate = (
  flowId: string,
  data: {
    /** @example "performer" */
    accountType: 'performer' | 'studio';
  },
  params: RequestParams = {}
): FetchResponse<RegistrationFlow> => {
  return fetch<RegistrationFlow>(`/gw/inner/v2/registration/account-type/${flowId}`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow
*
 * @tags Registration
 * @name InnerV2RegistrationChooseExistingPersonUpdate
 * @request PUT:/gw/inner/v2/registration/choose-existing-person/{flowId}
 * @secure
 * @response `200` `AddModelFlow` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationChooseExistingPersonUpdate = (
  flowId: string,
  data: {
    /** @example 1 */
    userId: number;
  },
  params: RequestParams = {}
): FetchResponse<AddModelFlow> => {
  return fetch<AddModelFlow>(`/gw/inner/v2/registration/choose-existing-person/${flowId}`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow
*
 * @tags Registration
 * @name InnerV2RegistrationChooseDisplayNameAndCategoryUpdate
 * @request PUT:/gw/inner/v2/registration/choose-display-name-and-category/{flowId}
 * @secure
 * @response `200` `RegistrationFlow` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationChooseDisplayNameAndCategoryUpdate = (
  flowId: string,
  data: {
    /** @example 1 */
    category: number;
    /** @example 2 */
    subcategory?: number;
    displayName: {
      /** @example "Awesome name" */
      value: string;
      /** @example "consented" */
      policyConsent: 'consented' | 'notRequired';
    };
    /** @deprecated */
    psid?: string;
  },
  params: RequestParams = {}
): FetchResponse<RegistrationFlow> => {
  return fetch<RegistrationFlow>(`/gw/inner/v2/registration/choose-display-name-and-category/${flowId}`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow
*
 * @tags Registration
 * @name InnerV2RegistrationPersonalDetailsUpdate
 * @request PUT:/gw/inner/v2/registration/personal-details/{flowId}
 * @secure
 * @response `200` `RegistrationFlow` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationPersonalDetailsUpdate = (
  flowId: string,
  data: {
    /** @example "First Name" */
    firstName: string;
    /** @example "Last Name" */
    lastName: string;
    /**
     * @format date
     * @example "2000-03-15"
     */
    dateOfBirth: string;
    /** @example 1 */
    sex: number;
    /** @example "LU" */
    country: string;
    /** @example "this field depends on country" */
    state: string | null;
    /** @example 124 */
    nationality: number;
    /** @example 352666777111 */
    phoneNumber?: string;
    /** @example 1 */
    ethnicity: number;
    /** @example "AmilyLi" */
    referredBy?: string | null;
  },
  params: RequestParams = {}
): FetchResponse<RegistrationFlow> => {
  return fetch<RegistrationFlow>(`/gw/inner/v2/registration/personal-details/${flowId}`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow
*
 * @tags Registration
 * @name InnerV2RegistrationPersonalDataUpdate
 * @request PUT:/gw/inner/v2/registration/personal-data/{flowId}
 * @secure
 * @response `200` `RegistrationFlow` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationPersonalDataUpdate = (
  flowId: string,
  data: {
    /** @example "female" */
    gender: string;
    /** @example 18 */
    age: number;
    /** @example "bald" */
    hairLength: string;
    /** @example 180 */
    height: number;
    /** @example "blue" */
    eyeColor: string;
    /** @example "blond" */
    hairColor: string;
    /** @example "petite" */
    build: string;
    /** @example "straight" */
    sexualPreference: string;
    /** @example "A cup" */
    breastSize?: string | null;
    /** @example "small" */
    buttSize?: string | null;
    /** @example "medium" */
    penisSize?: string | null;
  },
  params: RequestParams = {}
): FetchResponse<RegistrationFlow> => {
  return fetch<RegistrationFlow>(`/gw/inner/v2/registration/personal-data/${flowId}`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow
*
 * @tags Registration
 * @name InnerV2RegistrationDocumentDetailsUpdate
 * @request PUT:/gw/inner/v2/registration/document-details/{flowId}
 * @secure
 * @response `200` `RegistrationFlow` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationDocumentDetailsUpdate = (
  flowId: string,
  data: {
    /** @example 1 */
    idType: number;
    /** @example 12312313 */
    idNumber: string;
    /**
     * @format date
     * @example "11.12.2025"
     */
    idExpirationDate?: string | null;
    /** @example true */
    isPermanent: boolean;
  },
  params: RequestParams = {}
): FetchResponse<RegistrationFlow> => {
  return fetch<RegistrationFlow>(`/gw/inner/v2/registration/document-details/${flowId}`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow
*
 * @tags Registration
 * @name InnerV2RegistrationUploadDocumentsCreate
 * @request POST:/gw/inner/v2/registration/upload-documents/{flowId}
 * @secure
 * @response `200` `RegistrationFlow` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationUploadDocumentsCreate = (
  flowId: string,
  data: {
    /**
     * Image file
     * @format binary
     */
    idFront: File;
    /**
     * Image file
     * @format binary
     */
    idBack?: File | null;
    /**
     * Image file
     * @format binary
     */
    faceAndId: File;
    /** @example true */
    isConsentGiven?: boolean;
    /** @example true */
    isBioConsentGiven?: boolean | null;
  },
  params: RequestParams = {}
): FetchResponse<RegistrationFlow> => {
  return fetch<RegistrationFlow>(`/gw/inner/v2/registration/upload-documents/${flowId}`, {
    method: 'POST',
    body: data,
    contentType: ContentType.FormData,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow
*
 * @tags Registration
 * @name InnerV2RegistrationOranumPersonalDetailsUpdate
 * @request PUT:/gw/inner/v2/registration/oranum-personal-details/{flowId}
 * @secure
 * @response `200` `RegistrationFlow` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationOranumPersonalDetailsUpdate = (
  flowId: string,
  data: {
    /** @example "First Name" */
    firstName: string;
    /** @example "Last Name" */
    lastName: string;
    /**
     * @format date
     * @example "2000-03-15"
     */
    dateOfBirth: string;
    /** @example 1 */
    sex: number;
    /** @example "HappyReferrer" */
    referredBy?: string;
    displayName?: {
      /** @example "Awesome name" */
      value: string;
      /** @example "consented" */
      policyConsent: 'consented' | 'notRequired';
    } | null;
    /**
     * 64|65|66|67
     * @example 64
     */
    experienceLevel?: number | null;
    /** @example "+352123123123" */
    phoneNumber?: string | null;
  },
  params: RequestParams = {}
): FetchResponse<RegistrationFlow> => {
  return fetch<RegistrationFlow>(`/gw/inner/v2/registration/oranum-personal-details/${flowId}`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * No description
*
 * @tags Registration
 * @name InnerV2RegistrationCategoriesList
 * @summary Get list of categories and subcategories
 * @request GET:/gw/inner/v2/registration/categories
 * @secure
 * @response `200` `{
    data?: (Category)[],

}` Get list of categoriest with subcategories
 * @response `401` `void`
 * @response `429` `void`

*/
export const innerV2RegistrationCategoriesList = (
  params: RequestParams = {}
): FetchResponse<{
  data?: Category[];
}> => {
  return fetch<{
    data?: Category[];
  }>(`/gw/inner/v2/registration/categories`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Registration flow details
*
 * @tags Registration
 * @name InnerV2RegistrationEnumsList
 * @request GET:/gw/inner/v2/registration/enums
 * @secure
 * @response `200` `{
    data?: {
    accountType: ({
  \** @example "performer" *\
    id?: "performer" | "studio",
  \** @example "PERFORMER" *\
    label?: string,

})[],
    eyeColor: ({
  \** @example "black" *\
    id?: "blue" | "brown" | "black" | "green" | "grey" | "other",
  \** @example "BLACK" *\
    label?: string,

})[],
    hairLength: ({
  \** @example "crew_cut" *\
    id?: "bald" | "crew_cut" | "short" | "shoulder_length" | "long",
  \** @example "CREW_CUT" *\
    label?: string,

})[],
    hairColor: ({
  \** @example "brown" *\
    id?: "blonde" | "black" | "brown" | "fire_red" | "auburn" | "orange" | "pink" | "other",
  \** @example "BROWN" *\
    label?: string,

})[],
    sexualPreference?: ({
  \** @example "straight" *\
    id?: "bisexual" | "straight" | "lesbian" | "gay",
  \** @example "STRAIGHT" *\
    label?: string,

})[],
    height?: ({
  \** @example 180 *\
    id?: number,
  \** @example 180 *\
    label?: string,

})[],
    breastSize: ({
  \** @example "normal" *\
    id?: "tiny" | "normal" | "big" | "huge",
  \** @example "NORMAL" *\
    label?: string,

})[],
    penisSize: ({
  \** @example "normal" *\
    id?: "normal" | "big" | "huge",
  \** @example "NORMAL" *\
    label?: string,

})[],
    buttSize?: ({
  \** @example "normal" *\
    id?: "small" | "normal" | "big" | "huge",
  \** @example "NORMAL" *\
    label?: string,

})[],
    build: ({
  \** @example "skinny" *\
    id?: string,
  \** @example "SKINNY" *\
    label?: string,

})[],
    experienceLevel: ({
      \**
          * Experience Level id:
          *  * `64` - UP_TO_5_YEARS
          *  * `65` - FROM_5_TO_10_YEARS
          *  * `66` - FROM_10_TO_15_YEARS
          *  * `67` - FROM_15_YEARS
          * @example 64
          *\
    id?: 64 | 65 | 66 | 67,
  \** @example "UP_TO_5_YEARS" *\
    label?: string,

})[],
    idType: ({
      \**
          * IdType id:
          *  * `1` - ID_CARD
          *  * `2` - PASSPORT
          *  * `3` - LICENCE
          *  * `4` - OTHER
          * @example 1
          *\
    id?: 1 | 2 | 3 | 4,
  \** @example "ID_CARD" *\
    label?: string,

})[],
    gender: ({
  \** @example "female" *\
    id?: "female" | "male" | "transgender" | "hermaphrodite" | "shemale",
  \** @example "FEMALE" *\
    label?: string,

})[],
    sex: ({
      \**
          * Sex id:
          *  * `1` - FEMALE
          *  * `2` - MALE
          * @example 1
          *\
    id?: 1 | 2,
  \** @example "FEMALE" *\
    label?: string,

})[],
    dressSize: ({
  \** @example "xl" *\
    id?: "xs" | "s" | "m" | "l" | "xl",
  \** @example "XS" *\
    label?: string,

})[],

},

}` Get list of enums
 * @response `401` `void`
 * @response `429` `void`

*/
export const innerV2RegistrationEnumsList = (
  params: RequestParams = {}
): FetchResponse<{
  data?: {
    accountType: {
      /** @example "performer" */
      id?: 'performer' | 'studio';
      /** @example "PERFORMER" */
      label?: string;
    }[];
    eyeColor: {
      /** @example "black" */
      id?: 'blue' | 'brown' | 'black' | 'green' | 'grey' | 'other';
      /** @example "BLACK" */
      label?: string;
    }[];
    hairLength: {
      /** @example "crew_cut" */
      id?: 'bald' | 'crew_cut' | 'short' | 'shoulder_length' | 'long';
      /** @example "CREW_CUT" */
      label?: string;
    }[];
    hairColor: {
      /** @example "brown" */
      id?: 'blonde' | 'black' | 'brown' | 'fire_red' | 'auburn' | 'orange' | 'pink' | 'other';
      /** @example "BROWN" */
      label?: string;
    }[];
    sexualPreference?: {
      /** @example "straight" */
      id?: 'bisexual' | 'straight' | 'lesbian' | 'gay';
      /** @example "STRAIGHT" */
      label?: string;
    }[];
    height?: {
      /** @example 180 */
      id?: number;
      /** @example 180 */
      label?: string;
    }[];
    breastSize: {
      /** @example "normal" */
      id?: 'tiny' | 'normal' | 'big' | 'huge';
      /** @example "NORMAL" */
      label?: string;
    }[];
    penisSize: {
      /** @example "normal" */
      id?: 'normal' | 'big' | 'huge';
      /** @example "NORMAL" */
      label?: string;
    }[];
    buttSize?: {
      /** @example "normal" */
      id?: 'small' | 'normal' | 'big' | 'huge';
      /** @example "NORMAL" */
      label?: string;
    }[];
    build: {
      /** @example "skinny" */
      id?: string;
      /** @example "SKINNY" */
      label?: string;
    }[];
    experienceLevel: {
      /**
       * Experience Level id:
       *  * `64` - UP_TO_5_YEARS
       *  * `65` - FROM_5_TO_10_YEARS
       *  * `66` - FROM_10_TO_15_YEARS
       *  * `67` - FROM_15_YEARS
       * @example 64
       */
      id?: 64 | 65 | 66 | 67;
      /** @example "UP_TO_5_YEARS" */
      label?: string;
    }[];
    idType: {
      /**
       * IdType id:
       *  * `1` - ID_CARD
       *  * `2` - PASSPORT
       *  * `3` - LICENCE
       *  * `4` - OTHER
       * @example 1
       */
      id?: 1 | 2 | 3 | 4;
      /** @example "ID_CARD" */
      label?: string;
    }[];
    gender: {
      /** @example "female" */
      id?: 'female' | 'male' | 'transgender' | 'hermaphrodite' | 'shemale';
      /** @example "FEMALE" */
      label?: string;
    }[];
    sex: {
      /**
       * Sex id:
       *  * `1` - FEMALE
       *  * `2` - MALE
       * @example 1
       */
      id?: 1 | 2;
      /** @example "FEMALE" */
      label?: string;
    }[];
    dressSize: {
      /** @example "xl" */
      id?: 'xs' | 's' | 'm' | 'l' | 'xl';
      /** @example "XS" */
      label?: string;
    }[];
  };
}> => {
  return fetch<{
    data?: {
      accountType: {
        /** @example "performer" */
        id?: 'performer' | 'studio';
        /** @example "PERFORMER" */
        label?: string;
      }[];
      eyeColor: {
        /** @example "black" */
        id?: 'blue' | 'brown' | 'black' | 'green' | 'grey' | 'other';
        /** @example "BLACK" */
        label?: string;
      }[];
      hairLength: {
        /** @example "crew_cut" */
        id?: 'bald' | 'crew_cut' | 'short' | 'shoulder_length' | 'long';
        /** @example "CREW_CUT" */
        label?: string;
      }[];
      hairColor: {
        /** @example "brown" */
        id?: 'blonde' | 'black' | 'brown' | 'fire_red' | 'auburn' | 'orange' | 'pink' | 'other';
        /** @example "BROWN" */
        label?: string;
      }[];
      sexualPreference?: {
        /** @example "straight" */
        id?: 'bisexual' | 'straight' | 'lesbian' | 'gay';
        /** @example "STRAIGHT" */
        label?: string;
      }[];
      height?: {
        /** @example 180 */
        id?: number;
        /** @example 180 */
        label?: string;
      }[];
      breastSize: {
        /** @example "normal" */
        id?: 'tiny' | 'normal' | 'big' | 'huge';
        /** @example "NORMAL" */
        label?: string;
      }[];
      penisSize: {
        /** @example "normal" */
        id?: 'normal' | 'big' | 'huge';
        /** @example "NORMAL" */
        label?: string;
      }[];
      buttSize?: {
        /** @example "normal" */
        id?: 'small' | 'normal' | 'big' | 'huge';
        /** @example "NORMAL" */
        label?: string;
      }[];
      build: {
        /** @example "skinny" */
        id?: string;
        /** @example "SKINNY" */
        label?: string;
      }[];
      experienceLevel: {
        /**
         * Experience Level id:
         *  * `64` - UP_TO_5_YEARS
         *  * `65` - FROM_5_TO_10_YEARS
         *  * `66` - FROM_10_TO_15_YEARS
         *  * `67` - FROM_15_YEARS
         * @example 64
         */
        id?: 64 | 65 | 66 | 67;
        /** @example "UP_TO_5_YEARS" */
        label?: string;
      }[];
      idType: {
        /**
         * IdType id:
         *  * `1` - ID_CARD
         *  * `2` - PASSPORT
         *  * `3` - LICENCE
         *  * `4` - OTHER
         * @example 1
         */
        id?: 1 | 2 | 3 | 4;
        /** @example "ID_CARD" */
        label?: string;
      }[];
      gender: {
        /** @example "female" */
        id?: 'female' | 'male' | 'transgender' | 'hermaphrodite' | 'shemale';
        /** @example "FEMALE" */
        label?: string;
      }[];
      sex: {
        /**
         * Sex id:
         *  * `1` - FEMALE
         *  * `2` - MALE
         * @example 1
         */
        id?: 1 | 2;
        /** @example "FEMALE" */
        label?: string;
      }[];
      dressSize: {
        /** @example "xl" */
        id?: 'xs' | 's' | 'm' | 'l' | 'xl';
        /** @example "XS" */
        label?: string;
      }[];
    };
  }>(`/gw/inner/v2/registration/enums`, {
    method: 'GET',
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Get image uploaded during registration
*
 * @tags Registration
 * @name InnerV2RegistrationIdDocumentsDetail
 * @request GET:/gw/inner/v2/registration/{flowId}/id-documents/{type}/{expiration}/{signature}
 * @secure
 * @response `200` `File` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `429` `void`

*/
export const innerV2RegistrationIdDocumentsDetail = (
  flowId: string,
  type: 'front' | 'back' | 'snapshot',
  expiration: string,
  signature: string,
  params: RequestParams = {}
): FetchResponse<File> => {
  return fetch<File>(`/gw/inner/v2/registration/${flowId}/id-documents/${type}/${expiration}/${signature}`, {
    method: 'GET',
    responseType: 'blob',
    ...params,
  });
};

/**
 * @description Trigger registration flow migration from Portal to SPA
*
 * @tags Registration
 * @name InnerV2RegistrationMigrateFlowCreate
 * @request POST:/gw/inner/v2/registration/migrate-flow
 * @secure
 * @response `200` `MigrationStatus` Returned in case request was successful
 * @response `400` `{
  \** @example "problems/validation-error" *\
    type: string,
  \** @example "Validation failed" *\
    title: string,
    fields?: ({
  \** @example "90f5c6c9-ba85-453b-9f6e-a1b8b01b2464" *\
    code?: string,
  \** @example "Validation failed" *\
    message?: string,
  \** @example "field_path" *\
    path?: string,

})[] | null,

}`
 * @response `401` `void`
 * @response `403` `{
  \** @example "Forbidden" *\
    errorMessage: string,
  \** @example 403 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `404` `any`
 * @response `409` `{
  \** @example "Conflict" *\
    errorMessage: string,
  \** @example 409 *\
    errorCode: number,
  \** @example "ERROR" *\
    status: string,
  \** @example "" *\
    data: string,

}`
 * @response `429` `void`

*/
export const innerV2RegistrationMigrateFlowCreate = (
  data: {
    /** @example "livejasmin" */
    site: 'jasmin' | 'livejasmin' | 'lj' | 'docler' | 'sonicbox' | 'oranum' | 'bimbim' | null;
    /** @example 1 */
    userId: number;
  },
  params: RequestParams = {}
): FetchResponse<MigrationStatus> => {
  return fetch<MigrationStatus>(`/gw/inner/v2/registration/migrate-flow`, {
    method: 'POST',
    body: data,
    contentType: ContentType.Json,
    responseType: 'json',
    ...params,
  });
};

/**
 * @description Request an email confirmation
*
 * @tags Registration, Users, AccountSettings
 * @name RequestEmailConfirmation1
 * @request PUT:/gw/inner/v2/users/{userId}/email-confirmation-request
 * @secure
 * @response `204` `void` Email was resent successfully
 * @response `400` `{
    requestParams: object,
  \** @default "ERROR" *\
    status: "OK" | "ERROR",
      \**
          * @format int32
          * @example 0
          *\
    errorCode: number | null,
  \** @example "Error message" *\
    errorMessage: string | null,
    data: object,

}`
 * @response `401` `void`
 * @response `404` `{
    requestParams: object,
  \** @default "ERROR" *\
    status: "OK" | "ERROR",
      \**
          * @format int32
          * @example 0
          *\
    errorCode: number | null,
  \** @example "Error message" *\
    errorMessage: string | null,
    data: object,

}`
 * @response `429` `void`

*/
export const requestEmailConfirmation1 = <T>(
  userId: number,
  data: {
    /** @example "emailconfirmationcode123" */
    expiredCode?: string | null;
  },
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/gw/inner/v2/users/${userId}/email-confirmation-request`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    ...params,
  });
};

/**
 * @description Request email change
*
 * @tags Registration, Users, AccountSettings
 * @name RequestEmailChange
 * @request PUT:/gw/inner/v2/users/{userId}/email-change-request
 * @secure
 * @response `204` `void` Email change was requested successfully
 * @response `400` `{
    requestParams: object,
  \** @default "ERROR" *\
    status: "OK" | "ERROR",
      \**
          * @format int32
          * @example 0
          *\
    errorCode: number | null,
  \** @example "Error message" *\
    errorMessage: string | null,
    data: object,

}`
 * @response `401` `void`
 * @response `404` `{
    requestParams: object,
  \** @default "ERROR" *\
    status: "OK" | "ERROR",
      \**
          * @format int32
          * @example 0
          *\
    errorCode: number | null,
  \** @example "Error message" *\
    errorMessage: string | null,
    data: object,

}`
 * @response `429` `void`

*/
export const requestEmailChange = <T>(
  userId: number,
  data: {
    /** @example "user@doclerholding.com" */
    email: string;
    /**
     * Endpoint is used during and after the user registered. Current password is only required after user registered and wants to change an email.
     * @example "pass123word"
     */
    currentPassword?: string;
  },
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/gw/inner/v2/users/${userId}/email-change-request`, {
    method: 'PUT',
    body: data,
    contentType: ContentType.Json,
    ...params,
  });
};
