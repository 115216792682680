import { debounce } from 'throttle-debounce';
import settings from 'configurations/application';
import language from 'services/i18n/language';
import parse from 'utils/parse';

import isAuthenticated from './is-authenticated';
import getAuthData from './get-auth-data';
import utils from './utils';
import {
  OAuthRequestPath,
  type OAuthResponse,
  type OAuthResponseError,
  type RefreshAccessRequest,
  type PasswordGrantRequest,
  type RevokeTokenRequest,
  type OAuthAction,
} from './contracts';
import { emptyOAuthResponse, emptyOAuthResponseError } from './authentication.mock';

type HTTPRequestCallback = (error: OAuthResponseError | null, response: OAuthResponse) => void;

interface HTTPRequestMethods<T extends OAuthAction> {
  abort: () => HTTPRequestMethods<T>;
  send: (
    data: T extends OAuthAction.GET_TOKEN
      ? PasswordGrantRequest
      : T extends OAuthAction.REFRESH_TOKEN
        ? RefreshAccessRequest
        : RevokeTokenRequest,
    callback: HTTPRequestCallback
  ) => void;
}

type HTTPRequest = <A extends OAuthAction>(action: A) => HTTPRequestMethods<A>;

const httpRequest: HTTPRequest = <A extends OAuthAction>(action: A) => {
  const xhr = new XMLHttpRequest();
  const endpoint = parse.url(settings.envVars.apiGwBaseUri, OAuthRequestPath[action]);

  return {
    abort(): ReturnType<HTTPRequest> {
      xhr.abort();

      return this;
    },
    send: debounce(50, (data, callback) => {
      xhr.open('POST', endpoint, true);

      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      xhr.setRequestHeader('Accept-Language', language.current);

      if (isAuthenticated()) {
        xhr.setRequestHeader('Authorization', `Bearer ${getAuthData()?.accessToken ?? ''}`);
      }

      xhr.onload = () => {
        let response = {} as OAuthResponseError | OAuthResponse;

        try {
          response = JSON.parse((xhr.response as string) || '{}') as OAuthResponseError | OAuthResponse;
        } catch {
          response = {} as OAuthResponseError | OAuthResponse;
        } finally {
          if (xhr.status !== 200) {
            callback(response as OAuthResponseError, emptyOAuthResponse satisfies OAuthResponse);
          } else {
            callback(null, response as OAuthResponse);
          }
        }
      };

      xhr.onerror = () => {
        let response: OAuthResponseError = emptyOAuthResponseError;

        try {
          response = JSON.parse(xhr.response as string) as OAuthResponseError;
        } catch {
          response = emptyOAuthResponseError;
        } finally {
          callback(response, emptyOAuthResponse satisfies OAuthResponse);
        }
      };

      xhr.send(JSON.stringify(utils.request.mountBody(action, data)));
    }),
  };
};

export default httpRequest;
