import type { FetchResponse, RequestParams } from 'services/fetch';
import fetch, { ContentType } from 'services/fetch';

import type {
  PatchVideoCallRequest,
  PerformerDetailsListPagedResponse,
  PerformerPatchOperation,
  PerformerPrivateChatLogList,
  PerformerPromoPeriodTimeDetails,
  PerformerRating,
  StreamPerformerResponse,
  UpdatePerformerPropertiesRequest,
  UserDetailsResponse,
} from './data-contracts';

/**
 * @description Please use /gw/inner/v2/me endpoint instead. Documented in ProxyRegistration section
*
 * @tags Account_Services
 * @name GetMe
 * @summary Get the current User
 * @request GET:/v1/me
 * @deprecated
 * @secure
 * @response `200` `UserDetailsResponse`
 * @response `403` `{
    errors?: ({
  \** @example "403" *\
    status?: string,
  \** @example "Forbidden" *\
    title?: string,
  \** @example 1002 *\
    code?: number,
  \** @example "Insufficient permissions" *\
    details?: string,

})[],

}` Forbidden error message

*/
export const getMe = (params: RequestParams = {}): FetchResponse<UserDetailsResponse> => {
  return fetch<UserDetailsResponse>(`/v1/me`, {
    method: 'GET',
    ...params,
  });
};

/**
 * No description
*
 * @tags Account_Services
 * @name MePerformersList
 * @summary Returns a list of performers who has the same userId.
Returns a single performer if screenName filter provided (exact match)
 * @request GET:/v1/me/performers
 * @secure
 * @response `200` `PerformerDetailsListPagedResponse`
 * @response `400` `{
    errors?: ({
  \** @example "400" *\
    status?: string,
  \** @example "Invalid request" *\
    title?: string,
  \** @example 1000 *\
    code?: number,
    details?: string,

})[],

}` Bad request error message
 * @response `403` `void`
 * @response `404` `void`
 * @response `500` `{
    errors?: ({
  \** @example "500" *\
    status?: string,
  \** @example "Server error" *\
    title?: string,
  \** @example 1000 *\
    code?: number,
  \** @example "Unexpected error occurred" *\
    details?: string,

})[],

}` Error message

*/
export const mePerformersList = (
  query?: {
    /** Performer Screen Name */
    'filter[screenName]'?: string;
    /** Performer Display Name */
    'filter[displayName]'?: string;
    /** Part of performer screen name. */
    'filter[performerScreenNameLike]'?: string;
    /** Part of performer display name. */
    'filter[performerDisplayNameLike]'?: string;
    /** An array of the statuses for the filter. */
    'filter[statuses]'?: ('new' | 'pending' | 'rejected' | 'active' | 'inactive' | 'suspended' | 'closed')[];
    /**
     * The maximum number of result to get.
     * @format int32
     * @min 1
     * @max 100
     * @default 25
     */
    'page[limit]'?: number;
    /** The cursor key of the next page. */
    'page[cursor]'?: string;
  },
  params: RequestParams = {}
): FetchResponse<PerformerDetailsListPagedResponse> => {
  return fetch<PerformerDetailsListPagedResponse>(`/v1/me/performers`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Account_Services
 * @name MePerformersDetail
 * @summary Get the given performer, but only if the given user is an actor in that performer
 * @request GET:/v1/me/performers/{performerId}
 * @secure
 * @response `200` `StreamPerformerResponse`
 * @response `403` `void`
 * @response `404` `void`

*/
export const mePerformersDetail = (
  performerId: string,
  query?: {
    /** Extend the response with performer newbie status */
    extendWithNewbieStatus?: string;
  },
  params: RequestParams = {}
): FetchResponse<StreamPerformerResponse> => {
  return fetch<StreamPerformerResponse>(`/v1/me/performers/${performerId}`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Account_Services
 * @name MePerformersPartialUpdate
 * @summary Update a performer: used to block/unblock a member.
 * @request PATCH:/v1/me/performers/{performerId}
 * @secure
 * @response `204` `void` OK
 * @response `403` `void`
 * @response `404` `void`

*/
export const mePerformersPartialUpdate = <T>(
  performerId: string,
  data: PerformerPatchOperation,
  query?: {
    /** Extend the response with performer newbie status */
    extendWithNewbieStatus?: string;
  },
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/v1/me/performers/${performerId}`, {
    method: 'PATCH',
    query: query,
    body: data,
    contentType: ContentType.Json,
    ...params,
  });
};

/**
 * @description Each **key** must be the **name** of a property. Each **value** must be the **value** of the property. Possible keys: * isBirthdayVisible * used to set birthday visibility * liveShowChargeAmount * used to set price of live show * cam2camChargeAmount * used to set price of cam2cam * audioChargeRateModifier * used to set price of two-way audio * videoVoiceCallPrice * used to set price of video call * memberMessagePrice * used to set price of member message * countryBan * used to ban countries/states of member or guest * aboutMe * used to set the about me section * nextOnlineSession * used to set the next online session for the performer * isSubscriptionEnabled * used to set the performer profile to subscription based (must be true) * subscriptionPackageId * used to set the subscription package * isTwoWayAudioEnabled * used to toggle two way audio * audioSpokenLanguage * used to set spoken languages * isTwoWayAudioEnabled * used to toggle two way audio * referralConsentStatus * used to set the referral consent status * isMobileLiveDisabled * used to turn off/on mobile live * isReadReceiptsEnabled * used to turn off/on read receipts
*
 * @tags Account_Services
 * @name MePerformersPropertiesPartialUpdate
 * @summary Update performer properties
 * @request PATCH:/v1/me/performers/{performerId}/properties
 * @secure
 * @response `204` `void` OK
 * @response `400` `({
    errors?: (({
  \** @example "400" *\
    status?: string,
  \** @example "Bad request" *\
    title?: string,
      \**
          * @format int32
          * @example 4002
          *\
    code?: number,
  \** @example "One or more performer introduction properties contain spam" *\
    details?: string,
  \** List of the properties that contain spam (banned word or other reason). Properties without any spam are not listed here. *\
    properties?: ({
  \** @example "aboutMe" *\
    name?: "aboutMe" | "thingsLike" | "thingsDislike",
  \** @example "about me with Banned1 and Banned2 words" *\
    text?: string,
      \**
          * List of detected banned words in the text. Empty in case the text is considered spam for any other reason
          * @example ["Banned1","Banned2"]
          *\
    bannedWords?: (string)[],

})[],

}))[],

})` Bad request reporting spam detected
 * @response `403` `void`
 * @response `404` `void`
 * @response `500` `void`

*/
export const mePerformersPropertiesPartialUpdate = <T>(
  performerId: string,
  data: UpdatePerformerPropertiesRequest,
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/v1/me/performers/${performerId}/properties`, {
    method: 'PATCH',
    body: data,
    contentType: ContentType.Json,
    ...params,
  });
};

/**
 * No description
*
 * @tags Account_Services
 * @name MePerformersVideoCallPartialUpdate
 * @summary Set Video Call availability status for the given performer.
 * @request PATCH:/v1/me/performers/{performerId}/video-call
 * @secure
 * @response `204` `void` OK
 * @response `400` `void`
 * @response `403` `void`
 * @response `412` `{
    errors?: ({
  \** @example "412" *\
    status?: string,
  \** @example "Precondition Failed" *\
    title?: string,
  \** @example 5001 *\
    code?: number,
  \** @example "Required subscription token was not found" *\
    details?: string,

})[],

}` Precondition Failed

*/
export const mePerformersVideoCallPartialUpdate = <T>(
  performerId: string,
  data: PatchVideoCallRequest,
  params: RequestParams = {}
): FetchResponse<T> => {
  return fetch<T>(`/v1/me/performers/${performerId}/video-call`, {
    method: 'PATCH',
    body: data,
    contentType: ContentType.Json,
    ...params,
  });
};

/**
 * No description
*
 * @tags Account_Services
 * @name MePerformersChatLogsDetail
 * @summary Returns performer private chat logs between the performer and the member.
 * @request GET:/v1/me/performers/{performerId}/chat-logs
 * @secure
 * @response `200` `PerformerPrivateChatLogList`
 * @response `403` `void`
 * @response `404` `void`
 * @response `500` `void`

*/
export const mePerformersChatLogsDetail = (
  performerId: string,
  query: {
    /** Nickname of Member */
    memberNick: string;
  },
  params: RequestParams = {}
): FetchResponse<PerformerPrivateChatLogList> => {
  return fetch<PerformerPrivateChatLogList>(`/v1/me/performers/${performerId}/chat-logs`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Account_Services
 * @name MePerformersRatingDetail
 * @summary Returns performer rating. If memberNick passed returns member rating as well.
 * @request GET:/v1/me/performers/{performerId}/rating
 * @secure
 * @response `200` `PerformerRating`
 * @response `400` `void`
 * @response `403` `void`
 * @response `404` `void`
 * @response `500` `void`

*/
export const mePerformersRatingDetail = (
  performerId: string,
  query?: {
    /** Nickname of Member */
    memberNick?: string;
  },
  params: RequestParams = {}
): FetchResponse<PerformerRating> => {
  return fetch<PerformerRating>(`/v1/me/performers/${performerId}/rating`, {
    method: 'GET',
    query: query,
    ...params,
  });
};

/**
 * No description
*
 * @tags Account_Services
 * @name MePerformersPromoPeriodTimeDetail
 * @summary Returns details about promo period time of performer.
 * @request GET:/v1/me/performers/{performerId}/promo-period-time
 * @secure
 * @response `200` `PerformerPromoPeriodTimeDetails`
 * @response `403` `void`
 * @response `404` `void`
 * @response `500` `void`

*/
export const mePerformersPromoPeriodTimeDetail = (
  performerId: string,
  params: RequestParams = {}
): FetchResponse<PerformerPromoPeriodTimeDetails> => {
  return fetch<PerformerPromoPeriodTimeDetails>(`/v1/me/performers/${performerId}/promo-period-time`, {
    method: 'GET',
    ...params,
  });
};
