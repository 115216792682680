// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/** Profile picture search criteria array */
export interface ProfilePictureSearchCriteria {
  statuses?: Status[];
  mediaKeys?: VersionMediaKey[];
}
export interface ProfilePictureModel {
  /** Mongo Id (Alphanumeric 24 chars string unique identifier) */
  id: MongoId;
  /** @example "someScreenName" */
  performerScreenName: string;
  /** Profile Picture Category */
  category: Category;
  /** @example false */
  isSelected: boolean;
  /** Profile Picture Quality Rating */
  rating: Rating;
  /** Profile picture status */
  status: Status;
  statusReasons: StatusReason[];
  /** @example false */
  hasAdminSeen: boolean;
  /** DateTime in DATE_RFC3339 format */
  createdAt: DateTime;
  /** DateTime in DATE_RFC3339 format */
  updatedAt: DateTime;
  versions: ProfilePictureModelVersion[];
  crops: ProfilePictureModelCrop[];
  tags: ProfilePictureModelTag[];
}
export type ProfilePictureModelWithUploadUrl = ProfilePictureModel & {
  /** @example "https://upload/?token=5ac4bb8770522a31058cd324" */
  uploadUrl?: string;
};
export interface ProfilePictureModelVersion {
  /** Mongo Id (Alphanumeric 24 chars string unique identifier) */
  id: MongoId;
  /** Mongo Id (Alphanumeric 24 chars string unique identifier) */
  profilePictureId: MongoId;
  /** Profile picture version media key */
  mediaKey: VersionMediaKey;
  /** @example "https://gallery0.dditscdn.com/public/10/1bc75a99563eb34b66f36b1179c7f19e.jpg" */
  contentUri: string;
  /** @example 1600 */
  width: number;
  /** @example 900 */
  height: number;
  /** @example "jpg" */
  format: string;
  /** @example "#947C6A" */
  mainColor: string;
  /** @example 127116 */
  fileSize: number;
}
export interface ProfilePictureModelCrop {
  id: number;
  /** Mongo Id (Alphanumeric 24 chars string unique identifier) */
  profilePictureId: MongoId;
  /** Profile Picture Crop Name */
  name: CropName;
  /** Profile Picture Crop Source */
  source: CropSource;
  area: ProfilePictureModelCropArea;
}
export interface ProfilePictureModelCropArea {
  topLeft: ProfilePictureModelCropAreaCoordinates;
  bottomRight: ProfilePictureModelCropAreaCoordinates;
}
export interface ProfilePictureModelCropAreaCoordinates {
  unit: ProfilePictureModelCropAreaCoordinateUnit;
  x: ProfilePictureModelCropAreaCoordinateValue;
  y: ProfilePictureModelCropAreaCoordinateValue;
}
/** @example "%" */
export enum ProfilePictureModelCropAreaCoordinateUnit {
  Type = '%',
}
/**
 * @min 0
 * @max 100
 * @example 50
 */
export type ProfilePictureModelCropAreaCoordinateValue = number;
export interface ProfilePictureModelTag {
  id: number;
  /** Mongo Id (Alphanumeric 24 chars string unique identifier) */
  profilePictureId: MongoId;
  /** @example "fetish" */
  name: string;
  /** Profile Picture Tag Group */
  group: TagGroup;
  /** DateTime in DATE_RFC3339 format */
  createdAt: DateTime;
  /** DateTime in DATE_RFC3339 format */
  updatedAt: DateTime;
}
/**
 * DateTime in DATE_RFC3339 format
 * @format date-time
 * @example "2021-08-10T10:00:00+02:00"
 */
export type DateTime = string;
/**
 * Mongo Id (Alphanumeric 24 chars string unique identifier)
 * @pattern ^[a-z0-9]{24}$
 * @example "5d91d45b70522a06379fe98b'"
 */
export type MongoId = string;
/**
 * Profile Picture Category
 * @example "glamour"
 */
export enum Category {
  Erotic = 'erotic',
  Glamour = 'glamour',
}
/**
 * Profile Picture Quality Rating
 * @example "good"
 */
export enum Rating {
  Good = 'good',
  Bad = 'bad',
  None = 'none',
}
/**
 * Profile Picture Crop Name
 * @example "16_9"
 */
export enum CropName {
  Portrait = 'portrait',
  Type169 = '16_9',
  Type43 = '4_3',
}
/**
 * Profile Picture Crop Source
 * @example "original"
 */
export enum CropSource {
  Original = 'original',
  Type169 = '16_9',
}
/**
 * Profile Picture Tag Group
 * @example "willingness"
 */
export enum TagGroup {
  Appearance = 'appearance',
  Willingness = 'willingness',
}
/**
 * Profile picture version media key
 * @example "profile_picture_crop_16_9_1600_900_jpg"
 */
export enum VersionMediaKey {
  Parent = 'parent',
  ProfilePictureCrop435642Jpg = 'profile_picture_crop_4_3_56_42_jpg',
  ProfilePictureCrop43147110Jpg = 'profile_picture_crop_4_3_147_110_jpg',
  ProfilePictureCrop43800600Jpg = 'profile_picture_crop_4_3_800_600_jpg',
  ProfilePictureCrop9169001600Jpg = 'profile_picture_crop_9_16_900_1600_jpg',
  ProfilePictureCrop916504896Jpg = 'profile_picture_crop_9_16_504_896_jpg',
  ProfilePictureCrop1691600900Jpg = 'profile_picture_crop_16_9_1600_900_jpg',
  ProfilePictureCrop169896504Jpg = 'profile_picture_crop_16_9_896_504_jpg',
  ProfilePictureCrop169215121Jpg = 'profile_picture_crop_16_9_215_121_jpg',
  ProfilePictureCrop435642Webp = 'profile_picture_crop_4_3_56_42_webp',
  ProfilePictureCrop43147110Webp = 'profile_picture_crop_4_3_147_110_webp',
  ProfilePictureCrop43800600Webp = 'profile_picture_crop_4_3_800_600_webp',
  ProfilePictureCrop9169001600Webp = 'profile_picture_crop_9_16_900_1600_webp',
  ProfilePictureCrop916504896Webp = 'profile_picture_crop_9_16_504_896_webp',
  ProfilePictureCrop1691600900Webp = 'profile_picture_crop_16_9_1600_900_webp',
  ProfilePictureCrop169896504Webp = 'profile_picture_crop_16_9_896_504_webp',
  ProfilePictureCrop169215121Webp = 'profile_picture_crop_16_9_215_121_webp',
}
/**
 * Profile picture status
 * @example "inactive"
 */
export enum Status {
  Accepted = 'accepted',
  Rejected = 'rejected',
  Deleted = 'deleted',
  MwhValidationFailed = 'mwh_validation_failed',
  Waiting = 'waiting',
  Pending = 'pending',
  Cropping = 'cropping',
  Inactive = 'inactive',
}
export interface StatusReason {
  /** Profile picture status reason type */
  type: StatusReasonType;
  /** @example "The uploaded image contains explicit material and does not fit the Hot Flirt category rules." */
  text: string;
  params?: string[];
}
/**
 * Profile picture status reason type
 * @example "image_file_invalid"
 */
export enum StatusReasonType {
  Custom = 'custom',
  ImageFileInvalid = 'image_file_invalid',
  ImageFileFormatNotAcceptable = 'image_file_format_not_acceptable',
  ImageFileSizeTooSmall = 'image_file_size_too_small',
  ImageFileSizeTooLarge = 'image_file_size_too_large',
  ImageWidthTooSmall = 'image_width_too_small',
  ImageWidthTooLarge = 'image_width_too_large',
  ImageHeightTooSmall = 'image_height_too_small',
  ImageHeightTooLarge = 'image_height_too_large',
  ImageUnrecognizedReason = 'image_unrecognized_reason',
}
