import type {
  OAuthTokenRequestCommonProperties,
  PasswordGrantAccessTokenRequest,
  RefreshAccessTokenRequest,
  TokenRevocationRequest,
} from '../api/o-auth2-0/data-contracts';

type OAuthCommonRequest = OAuthTokenRequestCommonProperties;
type PasswordGrantRequest = Omit<PasswordGrantAccessTokenRequest, keyof OAuthCommonRequest>;
type RefreshAccessRequest = Omit<RefreshAccessTokenRequest, keyof OAuthCommonRequest>;
type RevokeTokenRequest = Omit<TokenRevocationRequest, keyof OAuthCommonRequest>;

/* eslint-disable camelcase */
interface OAuthResponse {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
  is_limited_access: boolean;
}

interface OAuthResponseError {
  error: string;
  error_description: number;
  hint: string;
  message: boolean;
}
/* eslint-enable camelcase */

interface Authentication {
  /** Date timestamp */
  expiresAt: number;
  accessToken: string;
  refreshToken: string;
}

enum OAuthAction {
  GET_TOKEN = 'GET_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  REVOKE_TOKEN = 'REVOKE_TOKEN',
}

enum OAuthRequestPath {
  GET_TOKEN = '/auth/oauth2/tokens',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  REFRESH_TOKEN = '/auth/oauth2/tokens',
  REVOKE_TOKEN = '/auth/oauth2/revoke-token',
}

export { OAuthAction, OAuthRequestPath };
export type {
  OAuthCommonRequest,
  PasswordGrantRequest,
  RefreshAccessRequest,
  RevokeTokenRequest,
  OAuthResponse,
  OAuthResponseError,
  Authentication,
};
