import signIn from './sign-in';
import signOut from './sign-out';
import getAuthData from './get-auth-data';
import getAccessToken from './get-access-token';
import isAuthenticated from './is-authenticated';
import isExpired from './is-expired';
import refreshToken from './refresh-token';
import event from './event-stream';

export default {
  getAuthData,
  getAccessToken,
  isAuthenticated,
  isExpired,
  refreshToken,
  signIn,
  signOut,
  event,
};
